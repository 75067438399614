import React, { useState, useEffect } from "react";
import "./style.css";
import {
  Form,
  InputGroup,
  Alert,
  Spinner,
  Accordion,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { useFormik } from "formik";
import html2canvas from "html2canvas";
import * as Yup from "yup";
import PdFFileSelfProduction from "../../Components/PdfFFileSelfProduction/index";
import { validateCNPJ } from "validations-br";
import MaskedInput from "react-maskedinput";
import arrowleft from "../../Assets/arrowleft.svg";
import arrowup from "../../Assets/arrowup.svg";
import arrowdown from "../../Assets/arrowdown.svg";
import { getUserId } from "../../Services/auth";
import faradayApi from "../../Services/faradayApi";
import { teslaApi } from "../../Services/teslaApi";
import LivreTable from "../SimulatorTable/LivreTable";
import APETable from "../SimulatorTable/APETable";
import SimulatorVPL from "./SimulatorVPL";
import { TIRPaybackTable } from "../SimulatorTable/TIRPaybackTable";
import APEXLivreTable from "../SimulatorTable/APEXLivreTable";
import { FiDownload } from "react-icons/fi";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFFile from "../PDFFile";
import SimulatorVPLAccumulated from "./SimulatorVPLAccumulated";
import PDFFileApe from "../PDFFileApe";
import fortlevsolarlogo from "../../Assets/fortlev-solar-logo.png";
import fortlevlogo from "../../Assets/fortlev-logo.png";
import studioEnergylogo from "../../Assets/studio-energy-logo.png";
import dsSolarlogo from "../../Assets/ds-solar-logo.png";
import tecnsyslogo from "../../Assets/tecnsys-logo.png";
import vanessalogo from "../../Assets/vanessa-logo.png";
import PDFFileApeXMl from "../PDFFileApeXMl";
import GDXAPETable from "../SimulatorTable/GDXAPETable";
import PDFFileGdXApe from "../PDFFileGdXApe";
import {
  i0Atacadista,
  i0Varejista,
  i1Atacadista,
  i1Varejista,
  i5Atacadista,
  i5Varejista,
} from "../../utils/EnergyPrice";

const FormSimuacao = () => {
  const [calc, setCalc] = useState("ML");
  const [distribuidoras, setDistribuidoras] = useState([]);
  const [cativoData, setCativoData] = useState(null);
  const [livreData, setLivreData] = useState(null);
  const [apeData, setApeData] = useState(null);
  const [gdData, setGdData] = useState(null);
  const [economyData, setEconomyData] = useState([]);
  const [buttonSelected, setButtonSelected] = useState("ML");
  const [simulacaoSelected, setSimulacaoSelected] = useState("");
  const [proposalData, setProposalData] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewSimulacaoData, setViewSimulacaoData] = useState(false);
  const [energyWarning, setEnergyWarning] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userCompany, setUserCompany] = useState("");
  const [proposalPermission, setProposalPermission] = useState(false);
  const [userPermission, setUserPermission] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const id = getUserId();

      try {
        var response = await faradayApi.get(`users/${id}`);
      } catch (err) {}

      const permissions = response?.data?.permissions?.split(",") ?? [];

      const hasProposalPermission = permissions.includes("proposal");

      if (
        hasProposalPermission ||
        response?.data?.role === "ADMIN" ||
        response?.data?.role === "SUPER_ADMIN"
      ) {
        setProposalPermission(true);
        setUserPermission(true);
      }

      setUserEmail(response?.data?.email);
      setUserCompany(
        response?.data?.company?.name
          ? response?.data?.company?.name
          : response?.data?.partner_company?.name
      );
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await teslaApi.get("distribuidoras");
      setDistribuidoras(data.sort((a, b) => a.name.localeCompare(b.name)));
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (simulacaoSelected !== "") {
      handleScroll();
    }
  }, [simulacaoSelected]);

  const handleViewData = () => {
    if (viewSimulacaoData) setViewSimulacaoData(false);
    else setViewSimulacaoData(true);
  };

  const formik = useFormik({
    initialValues: {
      calc: "ML",
      distribuidora: "",
      ths: "VERDE",
      grupoTarifario: "",
      demandaForaPonta: "",
      demandaForaPontaUltrapassagem: "",
      consumoForaPonta: "",
      consumoPonta: "",
      demandaPonta: "0",
      demandaPontaUltrapassagem: "0",
      desconto: 0,
      local: true,
      tipoInst: "tipoInst",
      cnpj: "",
      pis: "",
      cofins: "",
      icms: "",
      geracao: "",
      demandaUsina: "",
      potenciaUsina: "",
      custoProjeto: "",
      custoGestao: "",
      nomeCliente: "",
      isTaxCovid: true,
      isTaxScarcity: true,
      isEncargos: true,
      abateICMS: true,
      simultaneidade: 65,
      precoEnergia: "",
      precoEnergiaGeracao: ["98", "113", "124", "138", "149"],
      precoEnergia1: "98",
      precoEnergia2: "113",
      precoEnergia3: "124",
      precoEnergia4: "138",
      precoEnergia5: "149",
      isGerador: false,
      consumoGerador: "",
      custoDiesel: "2,00",
      custoGerador: "",
      generateProposal: false,
      taxaAtratividade: "-1",
      lcoe: "",
      clientLogo: null,
      partnerLogo: null,
      projectImage1: null,
      projectImage2: null,
      localization: "",
      modules: "",
      inverter: "",
      structure: "",
      items: [],
    },
    validationSchema: Yup.object({
      distribuidora: Yup.string().required(
        "A distribuidora deve ser informada"
      ),
      grupoTarifario: Yup.string().required(
        "O grupo tarifário deve ser informado"
      ),
      nomeCliente: Yup.string()
        .max(23, "O nome do cliente deve ter no máximo 23 caracteres")
        .test(
          "max-length",
          "O nome do cliente deve ter no máximo 23 caracteres",
          function (value) {
            if (value && value.length > 23) {
              return false; // Retorna false para indicar que a validação falhou
            }
            return true; // Retorna true para indicar que a validação passou
          }
        ),
      demandaForaPonta: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) <= 0 ? NaN : Number(value);
        })
        .typeError("Demanda fora de Ponta precisa ser um número valido")
        .required("Demanda Fora de Ponta deve ser informada"),
      demandaForaPontaUltrapassagem: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) <= 0 ? NaN : Number(value);
        })
        .typeError(
          "Demanda fora de Ponta Ultrapassagem precisa ser um número valido"
        ),
      demandaPonta: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" ? NaN : Number(value);
        })
        .typeError("Demanda de Ponta precisa ser um número valido")
        .required("Demanda de Ponta deve ser informado"),
      demandaPontaUltrapassagem: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" ? NaN : Number(value);
        })
        .typeError(
          "Demanda de Ponta Ultrapassagem precisa ser um número valido"
        ),
      consumoPonta: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) <= 0 ? NaN : Number(value);
        })
        .typeError("Consumo de Ponta precisa ser um número valido")
        .required("Consumo de Ponta deve ser informado"),
      consumoForaPonta: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) <= 0 ? NaN : Number(value);
        })
        .typeError("Consumo Fora de Ponta precisa ser um número valido")
        .required("Consumo Fora de Ponta deve ser informado"),
      consumoGerador: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) <= 0 ? NaN : Number(value);
        })
        .typeError("Consumo do gerador precisa ser um número valido"),
      custoDiesel: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" ? NaN : Number(value);
        })
        .typeError("Custo do diesel precisa ser um número valido"),
      custoGerador: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" ? NaN : Number(value);
        })
        .typeError("Custo do diesel precisa ser um número valido"),
      pis: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) < 0 ? NaN : Number(value);
        })
        .typeError("PIS precisa ser um número valido")
        .required("PIS deve ser informado"),
      cofins: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) < 0 ? NaN : Number(value);
        })
        .typeError("COFINS precisa ser um número valido")
        .required("COFINS deve ser informado"),
      icms: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) < 0 ? NaN : Number(value);
        })
        .typeError("ICMS precisa ser um número valido")
        .required("ICMS deve ser informado"),
      cnpj: Yup.string()
        .required("É necessário informar o CNPJ da Empresa")
        .test("is-cnpj", "CNPJ não é valido", (value) => validateCNPJ(value)),
      custoGestao: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" ? NaN : Number(value) >= 0 ? Number(value) : NaN;
        })
        .typeError("O custo operacional e CCEE deve ser um número positivo"),
      precoEnergia: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) <= 0 ? NaN : Number(value);
        })
        .typeError("O preço da energia deve ser um número")
        .positive("O preço da energia precisa ser um valor positivo")
        .required("O preço da energia deve ser informado"),
      precoEnergia1: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) <= 0 ? NaN : Number(value);
        })
        .typeError("O preço da energia deve ser um número")
        .positive("O preço da energia precisa ser um valor positivo")
        .required("O preço da energia deve ser informado"),
      precoEnergia2: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) <= 0 ? NaN : Number(value);
        })
        .typeError("O preço da energia deve ser um número")
        .positive("O preço da energia precisa ser um valor positivo")
        .required("O preço da energia deve ser informado"),
      precoEnergia3: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) <= 0 ? NaN : Number(value);
        })
        .typeError("O preço da energia deve ser um número")
        .positive("O preço da energia precisa ser um valor positivo")
        .required("O preço da energia deve ser informado"),
      precoEnergia4: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) <= 0 ? NaN : Number(value);
        })
        .typeError("O preço da energia deve ser um número")
        .positive("O preço da energia precisa ser um valor positivo")
        .required("O preço da energia deve ser informado"),
      precoEnergia5: Yup.number()
        .transform((_o, v) => {
          const value = v.replace(".", "w").replace(",", ".");
          return value === "" || Number(value) <= 0 ? NaN : Number(value);
        })
        .typeError("O preço da energia deve ser um número")
        .positive("O preço da energia precisa ser um valor positivo")
        .required("O preço da energia deve ser informado"),
      desconto: Yup.number()
        .moreThan(-1, "O tipo de desconto deve ser informado")
        .required("O tipo de desconto deve ser informado"),
      taxaAtratividade: Yup.number().when("generateProposal", {
        is: true,
        then: Yup.number()
          .transform((_o, v) => {
            const value = v.replace(".", "w").replace(",", ".");
            return value === "" || Number(value) < 0 ? NaN : Number(value);
          })
          .typeError("Este campo precisa ser um número valido")
          .required("Campo obrigatório"),
      }),
      lcoe: Yup.number().when("generateProposal", {
        is: true,
        then: Yup.number()
          .transform((_o, v) => {
            const value = v.replace(".", "w").replace(",", ".");
            return value === "" || Number(value) < 0 ? NaN : Number(value);
          })
          .typeError("Este campo precisa ser um número valido")
          .required("Campo obrigatório"),
      }),
      localization: Yup.string().when("generateProposal", {
        is: true,
        then: Yup.string().required("Campo obrigatório"),
      }),
      modules: Yup.string().when("generateProposal", {
        is: true,
        then: Yup.string().required("Campo obrigatório"),
      }),
      inverter: Yup.string().when("generateProposal", {
        is: true,
        then: Yup.string().required("Campo obrigatório"),
      }),
      structure: Yup.string().when("generateProposal", {
        is: true,
        then: Yup.string().required("Campo obrigatório"),
      }),
      items: Yup.array().when("generateProposal", {
        is: true,
        then: Yup.array()
          .min(1)
          .required("Campo obrigatório")
          .of(
            Yup.object({
              nome: Yup.string().required("Nome é obrigatório"),
              potencia: Yup.number()
                .transform((_o, v) => {
                  const value = v.replace(".", "w").replace(",", ".");
                  return value === "" || Number(value) <= 0
                    ? NaN
                    : Number(value);
                })
                .typeError("Este campo precisa ser um número valido")
                .required("Este campo deve ser informado"),
              geradorFotovoltaico: Yup.number()
                .transform((_o, v) => {
                  const value = v.replace(".", "w").replace(",", ".");
                  return value === "" || Number(value) <= 0
                    ? NaN
                    : Number(value);
                })
                .typeError("Este campo precisa ser um número valido")
                .required("Este campo deve ser informado"),
              projetoHomologacao: Yup.number()
                .transform((_o, v) => {
                  const value = v.replace(".", "w").replace(",", ".");
                  return value === "" || Number(value) <= 0
                    ? NaN
                    : Number(value);
                })
                .typeError("Este campo precisa ser um número valido")
                .required("Este campo deve ser informado"),
              epc: Yup.number()
                .transform((_o, v) => {
                  const value = v.replace(".", "w").replace(",", ".");
                  return value === "" || Number(value) <= 0
                    ? NaN
                    : Number(value);
                })
                .typeError("Este campo precisa ser um número valido")
                .required("Este campo deve ser informado"),
            })
          ),
      }),
      custoProjeto: Yup.number().when("calc", {
        is: (value) => Yup.string().oneOf(["APE", "MLXAPE"]).isValidSync(value),
        then: Yup.number()
          .transform((_o, v) => {
            const value = v.replace(".", "w").replace(",", ".");
            return value === "" || Number(value) < 0 ? NaN : Number(value);
          })
          .typeError("O valor de investimento deve ser um número")
          .positive("O valor de investimento precisa ser um valor positivo")
          .required("O valor de investimento deve ser informado"),
      }),
      demandaUsina: Yup.number().when("calc", {
        is: (value) =>
          Yup.string().oneOf(["APE", "MLXAPE", "GDXAPE"]).isValidSync(value),
        then: Yup.number()
          .transform((_o, v) => {
            const value = v.replace(".", "x").replace(",", ".");
            return value === "" || Number(value) <= 0 ? NaN : Number(value);
          })
          .typeError("A demanda da usina deve ser um número")
          .positive("A demanda da usina precisa ser um valor positivo")
          .required("A demanda da usina deve ser informada"),
      }),
      potenciaUsina: Yup.number().when("calc", {
        is: (value) =>
          Yup.string().oneOf(["APE", "MLXAPE", "GDXAPE"]).isValidSync(value),
        then: Yup.number()
          .transform((_o, v) => {
            const value = v.replace(".", "x").replace(",", ".");
            return value === "" || Number(value) <= 0 ? NaN : Number(value);
          })
          .typeError("A potência da usina deve ser um número")
          .positive("A potência da usina precisa ser um valor positivo")
          .required("A potência da usina deve ser informada"),
      }),
      geracao: Yup.number().when("calc", {
        is: (value) =>
          Yup.string().oneOf(["APE", "MLXAPE", "GDXAPE"]).isValidSync(value),
        then: Yup.number()
          .transform((_o, v) => {
            const value = v.replace(".", "x").replace(",", ".");
            return value === "" || Number(value) < 0 ? NaN : Number(value);
          })
          .typeError("A geração da usina deve ser um número")
          .positive("A geração da usina precisa ser um valor positivo")
          .required("A geração da usina deve ser informada"),
      }),
      simultaneidade: Yup.number().when("calc", {
        is: (value) => Yup.string().oneOf(["APE", "MLXAPE"]).isValidSync(value),
        then: Yup.number()
          .typeError("A simultaneidade deve ser um número")
          .moreThan(
            -1,
            "A simultaneidade deve ser um valor de porcentagem válido"
          )
          .lessThan(
            101,
            "A simultaneidade deve ser um valor de porcentagem válido"
          )
          .required("A simultaneidade deve ser informada"),
      }),
    }),
    onSubmit: async (values) => {
      if (!userPermission) {
        await handleUpload();
        //setSelectedFiles(null);
      } else {
        await handleUploadMerx();
      }
      setShowAlert(false);
      setLoading(true);
      setSimulacaoSelected("");
      generateProposalData();

      try {
        values = {
          ...values,
          pis: +values.pis.replace(".", "").replace(",", ".") / 100,
          cofins: +values.cofins.replace(".", "").replace(",", ".") / 100,
          icms: +values.icms.replace(".", "").replace(",", ".") / 100,
          demandaForaPonta: +values.demandaForaPonta
            .replace(".", "")
            .replace(",", "."),
          demandaForaPontaUltrapassagem: +values.demandaForaPontaUltrapassagem
            .replace(".", "")
            .replace(",", "."),
          demandaPonta: +values.demandaPonta.replace(".", "").replace(",", "."),
          demandaPontaUltrapassagem: +values.demandaPontaUltrapassagem
            .replace(".", "")
            .replace(",", "."),
          consumoForaPonta: +values.consumoForaPonta
            .replace(".", "")
            .replace(",", "."),
          consumoPonta: +values.consumoPonta.replace(".", "").replace(",", "."),
          consumoGerador: +values.consumoGerador
            .replace(".", "")
            .replace(",", "."),
          custoDiesel: +values.custoDiesel.replace(".", "").replace(",", "."),
          custoGerador: +values.custoGerador.replace(".", "").replace(",", "."),
          custoGestao: !!values.custoGestao
            ? +values.custoGestao.replace(".", "").replace(",", ".")
            : -1,
          custoProjeto: +values.custoProjeto.replace(".", "").replace(",", "."),
          geracao: +values.geracao.replace(".", "").replace(",", "."),
          demandaUsina: +values.potenciaUsina
            .replace(".", "")
            .replace(",", "."),
          potenciaUsina: +values.demandaUsina
            .replace(".", "")
            .replace(",", "."),
          precoEnergia: +values.precoEnergia1
            .replace(".", "")
            .replace(",", "."),
          precoEnergiaGeracao: [
            +values.precoEnergiaGeracao[0].replace(".", "").replace(",", "."),
            +values.precoEnergiaGeracao[1].replace(".", "").replace(",", "."),
            +values.precoEnergiaGeracao[2].replace(".", "").replace(",", "."),
            +values.precoEnergiaGeracao[3].replace(".", "").replace(",", "."),
            +values.precoEnergiaGeracao[4].replace(".", "").replace(",", "."),
          ],
          simultaneidade: parseFloat(values.simultaneidade) / 100,
          taxaAtratividade: +values.taxaAtratividade
            .replace(".", "")
            .replace(",", "."),
          lcoe: +values.lcoe.replace(".", "").replace(",", "."),
        };

        if (calc === "ML") {
          const cativoData = await teslaApi.post("simulator/cativo", {
            ...values,
          });
          const livreData = await teslaApi.post("simulator/ml", {
            ...values,
          });
          const economyData = await teslaApi.post("economies/ml", {
            ...values,
            precoEnergia: [
              +values.precoEnergia1.replace(".", "").replace(",", "."),
              +values.precoEnergia2.replace(".", "").replace(",", "."),
              +values.precoEnergia3.replace(".", "").replace(",", "."),
              +values.precoEnergia4.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
            ],
          });
          setCativoData(cativoData.data);
          setLivreData(livreData.data);
          setEconomyData(economyData.data);
          setSimulacaoSelected("ML");
        } else if (calc === "APE") {
          const cativoData = await teslaApi.post("simulator/cativo", {
            ...values,
          });
          const apeData = await teslaApi.post("simulator/ape", {
            ...values,
            precoEnergiaGeracao: values.precoEnergiaGeracao[0],
          });
          const economyData = await teslaApi.post("economies/ape", {
            ...values,
            precoEnergia: [
              +values.precoEnergia1.replace(".", "").replace(",", "."),
              +values.precoEnergia2.replace(".", "").replace(",", "."),
              +values.precoEnergia3.replace(".", "").replace(",", "."),
              +values.precoEnergia4.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
            ],
          });
          setCativoData(cativoData.data);
          setApeData(apeData.data);
          setEconomyData(economyData.data);
          setSimulacaoSelected("APE");
        } else if (calc === "MLXAPE") {
          const livreData = await teslaApi.post("simulator/ml", {
            ...values,
            custoGestao: -1,
          });
          const apeData = await teslaApi.post("simulator/ape", {
            ...values,
            precoEnergiaGeracao: values.precoEnergiaGeracao[0],
            custoGestao: -1,
          });
          const economyData = await teslaApi.post("economies/apexml", {
            ...values,
            custoGestao: -1,
            precoEnergia: [
              +values.precoEnergia1.replace(".", "").replace(",", "."),
              +values.precoEnergia2.replace(".", "").replace(",", "."),
              +values.precoEnergia3.replace(".", "").replace(",", "."),
              +values.precoEnergia4.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
            ],
          });
          setLivreData(livreData.data);
          setApeData(apeData.data);
          setEconomyData(economyData.data);
          setSimulacaoSelected("MLXAPE");
        } else if (calc === "GDXAPE") {
          const gdData = await teslaApi.post("simulator/gd", {
            ...values,
            precoEnergiaGeracao: values.precoEnergiaGeracao[0],
          });
          const apeData = await teslaApi.post("simulator/ape", {
            ...values,
            precoEnergiaGeracao: values.precoEnergiaGeracao[0],
            simultaneidade: 0,
          });
          const economyData = await teslaApi.post("economies/gdxape", {
            ...values,
            simultaneidade: 0,
            precoEnergia: [
              +values.precoEnergia1.replace(".", "").replace(",", "."),
              +values.precoEnergia2.replace(".", "").replace(",", "."),
              +values.precoEnergia3.replace(".", "").replace(",", "."),
              +values.precoEnergia4.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
              +values.precoEnergia5.replace(".", "").replace(",", "."),
            ],
          });
          setGdData(gdData.data);
          setApeData(apeData.data);
          setEconomyData(economyData.data);
          setSimulacaoSelected("GDXAPE");
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setShowAlert(true);
      }
    },
  });

  const demandaValid =
    formik.values.demandaPonta !== "0" || formik.values.demandaForaPonta !== "";

  const maiorDemanda =
    parseInt(formik.values.demandaForaPonta) +
    parseInt(formik.values.demandaPonta) +
    (!Number.isNaN(parseInt(formik.values.demandaForaPontaUltrapassagem))
      ? parseInt(formik.values.demandaForaPontaUltrapassagem)
      : 0) +
    +(!Number.isNaN(parseInt(formik.values.demandaPontaUltrapassagem))
      ? parseInt(formik.values.demandaPontaUltrapassagem)
      : 0);

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const calculateEnergyPrice = (e) => {
    const { value } = e.target;

    formik.setFieldValue("desconto", parseFloat(value));

    if (value === "0") {
      formik.setFieldValue(
        "precoEnergia",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i0Atacadista[0]
          : i0Varejista[0]
      );
      formik.setFieldValue(
        "precoEnergiaGeracao",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i5Atacadista
          : i5Varejista
      );
      formik.setFieldValue(
        "precoEnergia1",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i0Atacadista[0]
          : i0Varejista[0]
      );
      formik.setFieldValue(
        "precoEnergia2",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i0Atacadista[1]
          : i0Varejista[1]
      );
      formik.setFieldValue(
        "precoEnergia3",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i0Atacadista[2]
          : i0Varejista[2]
      );
      formik.setFieldValue(
        "precoEnergia4",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i0Atacadista[3]
          : i0Varejista[3]
      );
      formik.setFieldValue(
        "precoEnergia5",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i0Atacadista[4]
          : i0Varejista[4]
      );
    } else if (value === "0.5") {
      formik.setFieldValue(
        "precoEnergia",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i5Atacadista[0]
          : i5Varejista[0]
      );
      formik.setFieldValue(
        "precoEnergiaGeracao",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i5Atacadista
          : i5Varejista
      );
      formik.setFieldValue(
        "precoEnergia1",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i5Atacadista[0]
          : i5Varejista[0]
      );
      formik.setFieldValue(
        "precoEnergia2",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i5Atacadista[1]
          : i5Varejista[1]
      );
      formik.setFieldValue(
        "precoEnergia3",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i5Atacadista[2]
          : i5Varejista[2]
      );
      formik.setFieldValue(
        "precoEnergia4",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i5Atacadista[3]
          : i5Varejista[3]
      );
      formik.setFieldValue(
        "precoEnergia5",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i5Atacadista[4]
          : i5Varejista[4]
      );
    } else {
      formik.setFieldValue(
        "precoEnergia",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i1Atacadista[0]
          : i1Varejista[0]
      );
      formik.setFieldValue(
        "precoEnergiaGeracao",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i5Atacadista
          : i5Varejista
      );
      formik.setFieldValue(
        "precoEnergia1",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i1Atacadista[0]
          : i1Varejista[0]
      );
      formik.setFieldValue(
        "precoEnergia2",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i1Atacadista[1]
          : i1Varejista[1]
      );
      formik.setFieldValue(
        "precoEnergia3",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i1Atacadista[2]
          : i1Varejista[2]
      );
      formik.setFieldValue(
        "precoEnergia4",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i1Atacadista[3]
          : i1Varejista[3]
      );
      formik.setFieldValue(
        "precoEnergia5",
        maiorDemanda >= 500 || buttonSelected !== "ML"
          ? i1Atacadista[4]
          : i1Varejista[4]
      );
    }
  };

  const resetGrupoTarifario = (e) => {
    const { value } = e.target;
    formik.setFieldValue("grupoTarifario", "");
    formik.setFieldValue("distribuidora", value);
  };

  const resetTHSWithGrupoTarifario = (e) => {
    const { value } = e.target;
    if (value === "A3") formik.setFieldValue("ths", "AZUL");
    formik.setFieldValue("grupoTarifario", value);
  };

  const resetDiscountTypeWithDemandaForaPonta = (e) => {
    const { value } = e.target;
    formik.setFieldValue("desconto", -1);
    setEnergyWarning(false);
    formik.setFieldValue("demandaForaPonta", value);
  };

  const resetDiscountTypeWithDemandaPonta = (e) => {
    const { value } = e.target;
    formik.setFieldValue("desconto", -1);
    setEnergyWarning(false);
    formik.setFieldValue("demandaPonta", value);
  };

  const resetDiscounWithtUltrapassagemForaPontaType = (e) => {
    const { value } = e.target;
    formik.setFieldValue("desconto", -1);
    setEnergyWarning(false);
    formik.setFieldValue("demandaForaPontaUltrapassagem", value);
  };

  const resetDiscountWithUltrapassagemPontaType = (e) => {
    const { value } = e.target;
    formik.setFieldValue("desconto", -1);
    setEnergyWarning(false);
    formik.setFieldValue("demandaPontaUltrapassagem", value);
  };

  const resetSimultaneidade = (e) => {
    formik.setFieldValue("simultaneidade", !formik.values.local ? 65 : 0);
    formik.setFieldValue("local", !formik.values.local);
  };

  const resetTHS = (e) => {
    const { value } = e.target;
    formik.setFieldValue("demandaPonta", "0");
    formik.setFieldValue("demandaPontaUltrapassagem", "0");
    formik.setFieldValue("ths", value);
  };

  const resetEnergyGeracao = (type, value) => {
    formik.setFieldValue("precoEnergiaGeracao", [
      type === "precoEnergia1" ? value : formik.values.precoEnergia1,
      type === "precoEnergia2" ? value : formik.values.precoEnergia2,
      type === "precoEnergia3" ? value : formik.values.precoEnergia3,
      type === "precoEnergia4" ? value : formik.values.precoEnergia4,
      type === "precoEnergia5" ? value : formik.values.precoEnergia5,
    ]);
  };

  const resetEnergyPrice1 = (e) => {
    const { value } = e.target;
    setEnergyWarning(true);
    formik.setFieldValue("precoEnergia1", value);
    if (formik.values.desconto === 0.5)
      resetEnergyGeracao("precoEnergia1", value);
  };

  const resetEnergyPrice2 = (e) => {
    const { value } = e.target;
    setEnergyWarning(true);
    formik.setFieldValue("precoEnergia2", value);
    if (formik.values.desconto === 0.5)
      resetEnergyGeracao("precoEnergia2", value);
  };

  const resetEnergyPrice3 = (e) => {
    const { value } = e.target;
    setEnergyWarning(true);
    formik.setFieldValue("precoEnergia3", value);
    if (formik.values.desconto === 0.5)
      resetEnergyGeracao("precoEnergia3", value);
  };

  const resetEnergyPrice4 = (e) => {
    const { value } = e.target;
    setEnergyWarning(true);
    formik.setFieldValue("precoEnergia4", value);
    if (formik.values.desconto === 0.5)
      resetEnergyGeracao("precoEnergia4", value);
  };

  const resetEnergyPrice5 = (e) => {
    const { value } = e.target;
    setEnergyWarning(true);
    formik.setFieldValue("precoEnergia5", value);
    if (formik.values.desconto === 0.5)
      resetEnergyGeracao("precoEnergia5", value);
  };

  const resetCovid = (e) => {
    formik.setFieldValue("isTaxCovid", !formik.values.isTaxCovid);
  };

  const resetScarcity = (e) => {
    formik.setFieldValue("isTaxScarcity", !formik.values.isTaxScarcity);
  };

  const resetEncargos = (e) => {
    formik.setFieldValue("isEncargos", !formik.values.isEncargos);
  };

  const resetAbateICMS = (e) => {
    formik.setFieldValue("abateICMS", !formik.values.abateICMS);
  };

  const resetGenerationConsume = (e) => {
    const { value } = e.target;
    formik.setFieldValue("consumoGerador", value);
    formik.setFieldValue(
      "custoGerador",
      (
        Number(value.replace(",", ".")) *
        Number(formik.values.custoDiesel.replace(",", "."))
      )
        .toFixed(2)
        .replace(".", ",")
    );
  };

  const resetGenerationCost = (e) => {
    const { value } = e.target;
    formik.setFieldValue("custoGerador", value);
    formik.setFieldValue(
      "consumoGerador",
      (
        Number(value.replace(",", ".")) /
        Number(formik.values.custoDiesel.replace(",", "."))
      )
        .toFixed(2)
        .replace(".", ",")
    );
  };

  const resetDieselCost = (e) => {
    const { value } = e.target;
    formik.setFieldValue("custoDiesel", value);
    formik.setFieldValue("custoGerador", "");
    formik.setFieldValue("consumoGerador", "");
  };

  const addItem = () => {
    formik.setFieldValue("items", [
      ...formik.values.items,
      { nome: "", potencia: "", geradorFotovoltaico: "" },
    ]);
  };

  const removeItem = (index) => {
    const newItems = formik.values.items.filter((_, i) => i !== index);
    formik.setFieldValue("items", newItems);
  };

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;
    const newItems = [...formik.values.items];
    newItems[index][name] = value;
    formik.setFieldValue("items", newItems);
  };

  const handleFileChange = (event, path) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      formik.setFieldValue(path, selectedFile);
    }
  };

  const fileName = formik.values.nomeCliente
    ? formik.values.nomeCliente
        .split(" ")[0]
        .toLowerCase()
        .concat(
          `_viabilidade_${
            Number(formik.values.demandaForaPonta.replace(",", ".")) >= 500
              ? "atacadista"
              : "varejista"
          }.pdf`
        )
    : `viabilidade`.concat(
        `_${
          Number(formik.values.demandaForaPonta.replace(",", ".")) >= 500
            ? "atacadista"
            : "varejista"
        }.pdf`
      );

  const fileProposalName = formik.values.nomeCliente
    ? formik.values.nomeCliente
        .split(" ")[0]
        .toLowerCase()
        .concat("_proposta_autoproducao.pdf")
    : `proposta_autoproducao.pdf`;

  const simulatorData = {
    ...formik.values,
    distribuidora: formik.values.distribuidora,
    ths: formik.values.ths,
    grupo: formik.values.grupoTarifario,
    desconto: formik.values.desconto,
    custoGestao: formik.values.custoGestao,
    cliente: formik.values.nomeCliente,
    pis: formik.values.pis,
    icms: formik.values.icms,
    cofins: formik.values.cofins,
    demandaForaPonta: formik.values.demandaForaPonta,
    demandaPonta: formik.values.demandaPonta,
    consumoPonta: formik.values.consumoPonta,
    consumoForaPonta: formik.values.consumoForaPonta,
    precoEnergia1: formik.values.precoEnergia1,
    precoEnergia2: formik.values.precoEnergia2,
    precoEnergia3: formik.values.precoEnergia3,
    precoEnergia4: formik.values.precoEnergia4,
    precoEnergia5: formik.values.precoEnergia5,
  };

  const generateProposalData = () => {
    const proposalData = JSON.parse(
      JSON.stringify({
        todayDate: new Date(Date.now()).toLocaleString("pt-br").split(",")[0],
        userEmail,
        clientName: formik.values.nomeCliente,
        localization: formik.values.localization,
        modules: formik.values.modules,
        inverter: formik.values.inverter,
        structure: formik.values.structure,
        items: formik.values.items,
        lcoe: +(+formik.values.lcoe.replace(".", "").replace(",", ".")).toFixed(
          2
        ),
        potenciaUsina: +(
          +formik.values.potenciaUsina.replace(".", "").replace(",", ".") / 1000
        ).toFixed(2),
        demandaUsina: +(
          +formik.values.demandaUsina.replace(".", "").replace(",", ".") / 1000
        ).toFixed(2),
        geracaoMediaAnual: +(
          (+formik.values.geracao.replace(".", "").replace(",", ".") / 1000) *
          12
        ).toFixed(2),
        percentualConsumoAtendido: +(
          (+formik.values.geracao.replace(".", "").replace(",", ".") * 100) /
          (+formik.values.consumoPonta.replace(".", "").replace(",", ".") +
            +formik.values.consumoForaPonta.replace(".", "").replace(",", "."))
        ).toFixed(0),
      })
    );

    if (proposalData.percentualConsumoAtendido > 100)
      proposalData.percentualConsumoAtendido = 100;

    proposalData.clientLogo = formik.values.clientLogo;
    proposalData.partnerLogo = formik.values.partnerLogo;
    proposalData.projectImage1 = formik.values.projectImage1;
    proposalData.projectImage2 = formik.values.projectImage2;

    for (const item of proposalData.items) {
      item.potencia = +item.potencia.replace(".", "").replace(",", ".");
      item.geradorFotovoltaico = +item.geradorFotovoltaico
        .replace(".", "")
        .replace(",", ".");
      item.projetoHomologacao = +item.projetoHomologacao
        .replace(".", "")
        .replace(",", ".");
      item.epc = +item.epc.replace(".", "").replace(",", ".");

      item.geradorFotovoltaicoCost = +(
        item.geradorFotovoltaico *
        (1000000 * item.potencia)
      ).toFixed(2);
      item.projetoHomologacaoCost = +(
        item.projetoHomologacao *
        (1000000 * item.potencia)
      ).toFixed(2);
      item.epcCost = +(item.epc * (1000000 * item.potencia)).toFixed(2);

      item.totalValue = +(
        item.geradorFotovoltaico +
        item.projetoHomologacao +
        item.epc
      ).toFixed(2);
      item.totalCost = +(
        item.geradorFotovoltaicoCost +
        item.projetoHomologacaoCost +
        item.epcCost
      ).toFixed(2);

      item.geradorFotovoltaicoCapex = +(
        (item.geradorFotovoltaicoCost * 100) /
        item.totalCost
      ).toFixed(2);
      item.projetoHomologacaoCapex = +(
        (item.projetoHomologacaoCost * 100) /
        item.totalCost
      ).toFixed(2);
      item.epcCapex = +((item.epcCost * 100) / item.totalCost).toFixed(2);
    }

    setProposalData(proposalData);
  };

  const [screenShotChart, setScreenShotChart] = useState("");

  useEffect(() => {
    setTimeout(() => {
      handleScreenShot();
    }, 1000);
  }, [simulacaoSelected]);

  const [selectedFile, setSelectedFiles] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);

  const handleFileSelect = (event) => {
    setSelectedFiles(event.target.files[0]);
    setShowAlert(false);
  };

  const handleUploadMerx = async () => {
    setFileLoading(true);

    const userId = getUserId();

    const dataUpload = {
      distributor: formik.values.distribuidora,
      userId: userId,
      ths: formik.values.ths,
      tariffGroup: formik.values.grupoTarifario,
      discount: formik.values.desconto.toString(),
      managementCost: formik.values.custoGestao,
      clientName: formik.values.nomeCliente,
      cnpj: formik.values.cnpj,
      pis: formik.values.pis,
      icms: formik.values.icms,
      cofins: formik.values.cofins,
      offPeakDemand: formik.values.demandaForaPonta,
      offPeakDemandOvertaking: formik.values.demandaForaPontaUltrapassagem,
      offPeakConsume: formik.values.consumoForaPonta,
      peakDemandOvertaking: formik.values.demandaPontaUltrapassagem,
      peakDemand: formik.values.demandaPonta,
      peakConsume: formik.values.consumoPonta,
      projectCost: formik.values.custoProjeto,
      generation: formik.values.geracao,
      localPowerPlant: formik.values.local ? "true" : "false",
      simultaneidade: formik.values.simultaneidade.toString(),
      potencyPowerPlant: formik.values.demandaUsina,
    };

    try {
      await faradayApi.post(`simulator-invoice/merx`, dataUpload);
      setFileLoading(false);
      setSelectedFiles(null);
    } catch (error) {
      console.log(error);
      window.alert("Erro ao enviar sua simulação, por favor, tente novamente!");
      setFileLoading(false);
      setSelectedFiles(null);
    }
  };

  const handleUpload = async () => {
    try {
      setFileLoading(true);

      const userId = getUserId();
      const formData = new FormData();

      formData.append("file", selectedFile);
      formData.append("distributor", formik.values.distribuidora);
      formData.append("userId", userId);
      formData.append("ths", formik.values.ths);
      formData.append("tariffGroup", formik.values.grupoTarifario);
      formData.append("discount", formik.values.desconto);
      formData.append("managementCost", formik.values.custoGestao);
      formData.append("clientName", formik.values.nomeCliente);
      formData.append("cnpj", formik.values.cnpj);
      formData.append("pis", formik.values.pis);
      formData.append("icms", formik.values.icms);
      formData.append("cofins", formik.values.cofins);
      formData.append("offPeakDemand", formik.values.demandaForaPonta);
      formData.append(
        "offPeakDemandOvertaking",
        formik.values.demandaForaPontaUltrapassagem
      );
      formData.append("offPeakConsume", formik.values.consumoForaPonta);
      formData.append(
        "peakDemandOvertaking",
        formik.values.demandaPontaUltrapassagem
      );
      formData.append("peakDemand", formik.values.demandaPonta);
      formData.append("peakConsume", formik.values.consumoPonta);
      formData.append("projectCost", formik.values.custoProjeto);
      formData.append("generation", formik.values.geracao);
      formData.append("localPowerPlant", formik.values.local);
      formData.append("simultaneidade", formik.values.simultaneidade);
      formData.append("potencyPowerPlant", formik.values.demandaUsina);

      if (!selectedFile) {
        window.alert("Selecione alguma fatura para enviar!");
        setFileLoading(false);
        return;
      }

      await faradayApi.post(`simulator-invoice`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setFileLoading(false);

      // Evitar manipulação direta do DOM para limpar o input
      setSelectedFiles(null);

      // Resetar o input de arquivo de forma segura
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        // Criar um novo input para substituir o antigo
        const newInput = document.createElement("input");
        Object.assign(newInput, {
          type: "file",
          className: fileInput.className,
          accept: fileInput.accept,
          multiple: fileInput.multiple,
        });

        // Adicionar o evento onChange
        newInput.addEventListener("change", handleFileSelect);

        // Se o elemento pai existir, substituir com segurança
        if (fileInput.parentNode) {
          try {
            fileInput.parentNode.replaceChild(newInput, fileInput);
          } catch (e) {
            // Se falhar a substituição, simplesmente ignore
            console.log("Não foi possível resetar o input de arquivo");
          }
        }
      }
    } catch (error) {
      console.log(error);
      window.alert("Erro ao enviar sua fatura, por favor, tente novamente!");
      setFileLoading(false);
      setSelectedFiles(null);
    }
  };

  const handleScreenShot = async () => {
    const element = document.querySelector(".simulator-vpl");
    const canvas = await html2canvas(element, {
      scale: 3,
      allowTaint: false,
      useCORS: true,
    });
    const data = canvas.toDataURL("image/jpeg");

    setScreenShotChart(data);
  };

  function checkCompanyLogo() {
    if (userCompany && userCompany.includes("FORTLEV ENERGIA SOLAR"))
      return { hasImage: true, styleType: "FORTLEV", logo: fortlevsolarlogo };

    if (userCompany && userCompany.includes("FORTLEV"))
      return { hasImage: true, styleType: "FORTLEV", logo: fortlevlogo };

    if (userCompany && userCompany.includes("STUDIO ENERGY"))
      return { hasImage: true, styleType: "STUDIO", logo: studioEnergylogo };

    if (userCompany && userCompany.includes("DS ENGENHARIA"))
      return { hasImage: true, styleType: "DS", logo: dsSolarlogo };

    if (userCompany && userCompany.includes("Tecnsys"))
      return { hasImage: true, styleType: "FORTLEV", logo: tecnsyslogo };

    if (userCompany && userCompany.includes("VM PROJETOS"))
      return { hasImage: true, styleType: "STUDIO", logo: vanessalogo };

    return { hasImage: false };
  }

  return (
    <div className="container">
      <div id="simuladorId" className="container-formSimulacao">
        <div className="simulacao-header-title">
          {simulacaoSelected === "" ? (
            <>
              <h1>Simulação de economia</h1>
              <h2>
                Preencha o formulário abaixo e tenha em mãos um estudo
                comparativo entre Autoprodutor de energia, Mercado Cativo e
                Mercado Livre
              </h2>
            </>
          ) : (
            <>
              <h1>Resultado da simulação de economia</h1>
              <h2>
                Este não é um estudo final. Ele não considera todas as
                variáveis, caso precise de um estudo mais aprofundado, entre em
                contato conosco
              </h2>
            </>
          )}
        </div>

        {simulacaoSelected === "" ? (
          <Form
            className="simulacao-form"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <div className="simulacao-buttons">
              <p
                className={
                  buttonSelected === "ML"
                    ? "simulacao-button-selected"
                    : "simulacao-button"
                }
                onClick={() => {
                  setCalc("ML");
                  formik.setFieldValue("calc", "ML");
                  formik.setFieldValue("desconto", -1);
                  setButtonSelected("ML");
                }}
              >
                MERCADO LIVRE
              </p>
              <p
                className={
                  buttonSelected === "APE"
                    ? "simulacao-button-selected"
                    : "simulacao-button"
                }
                onClick={() => {
                  setCalc("APE");
                  formik.setFieldValue("calc", "APE");
                  formik.setFieldValue("desconto", -1);
                  setButtonSelected("APE");
                }}
              >
                AUTOPRODUTOR
              </p>
              <p
                className={
                  buttonSelected === "MLXAPE"
                    ? "simulacao-button-selected"
                    : "simulacao-button"
                }
                onClick={() => {
                  setCalc("MLXAPE");
                  formik.setFieldValue("calc", "APE");
                  formik.setFieldValue("desconto", -1);
                  setButtonSelected("MLXAPE");
                }}
              >
                MERCADO LIVRE X AUTOPRODUTOR
              </p>
              <p
                className={
                  buttonSelected === "GDXAPE"
                    ? "simulacao-button-selected"
                    : "simulacao-button"
                }
                onClick={() => {
                  setCalc("GDXAPE");
                  formik.setFieldValue("calc", "GDXAPE");
                  formik.setFieldValue("desconto", -1);
                  setButtonSelected("GDXAPE");
                }}
              >
                GD X AUTOPRODUTOR
              </p>
            </div>

            <div>
              <span className="simulacao-form-header">Dados do cliente</span>
              <hr />
            </div>

            <div className="formLine">
              <Form.Group
                controlId="simulationCNPJ"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  CNPJ da Empresa <span>*</span>
                </Form.Label>
                <Form.Control
                  name="cnpj"
                  placeholder="00.000.000/0000-00"
                  as={MaskedInput}
                  mask="11.111.111/1111-11"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cnpj}
                  isInvalid={!!formik.errors.cnpj}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.cnpj}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                controlId="nomeCliente"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Nome do cliente
                </Form.Label>
                <Form.Control
                  name="nomeCliente"
                  placeholder="Sigla ou por extenso"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nomeCliente}
                  isInvalid={!!formik.errors.nomeCliente}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.nomeCliente}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                controlId="custoGestao"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label
                  className="simulacao-form-title"
                  style={{ display: "flex", flexDirection: "row", gap: "8px" }}
                >
                  Custo Operacional e CCEE{" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        O custo será calculado automaticamente caso nenhum valor
                        seja enviado.
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        width: "16px",
                        height: "16px",
                        borderRadius: "100%",
                        backgroundColor: "#0072ce",
                        display: "flex",
                        color: "white",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                      }}
                    >
                      ?
                    </div>
                  </OverlayTrigger>
                </Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>R$</InputGroup.Text>
                  <Form.Control
                    name="custoGestao"
                    placeholder="Em reais"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.custoGestao}
                    isInvalid={!!formik.errors.custoGestao}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.custoGestao}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>

            <div className="formLine">
              <Form.Group
                controlId="simulationCNPJ"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Distribuidora <span>*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="distribuidora"
                  onChange={(e) => resetGrupoTarifario(e)}
                  onBlur={formik.handleBlur}
                  value={formik.values.distribuidora}
                >
                  <option key="" hidden>
                    Selecione a distribuidora
                  </option>

                  {distribuidoras.map((d) => (
                    <option key={d.id} value={d.value}>
                      {d.name}
                    </option>
                  ))}
                </Form.Control>
                <span style={{ color: "red" }}>
                  {formik.errors.distribuidora}
                </span>
              </Form.Group>

              <Form.Group
                controlId="simulationCNPJ"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Grupo Tarifário <span>*</span>
                </Form.Label>

                <Form.Control
                  as="select"
                  name="grupoTarifario"
                  onChange={(e) => resetTHSWithGrupoTarifario(e)}
                  onBlur={formik.handleBlur}
                  value={formik.values.grupoTarifario}
                  disabled={!!!formik.values.distribuidora}
                >
                  <option value="" hidden>
                    Selecione uma opção
                  </option>

                  {distribuidoras.map(
                    (d) =>
                      d.name === formik.values.distribuidora &&
                      d.tarifas.map((t) => (
                        <option key={d.id} value={t}>
                          {t}
                        </option>
                      ))
                  )}
                </Form.Control>
                <span style={{ color: "red" }}>
                  {formik.errors.grupoTarifario}
                </span>
              </Form.Group>

              <Form.Group
                controlId="simulationNumber"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  THS <span>*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="ths"
                  onChange={(e) => resetTHS(e)}
                  onBlur={formik.handleBlur}
                  value={formik.values.ths}
                >
                  <option
                    value="VERDE"
                    disabled={formik.values.grupoTarifario === "A3"}
                  >
                    Verde
                  </option>
                  <option value="AZUL">Azul</option>
                </Form.Control>
              </Form.Group>
            </div>

            <div>
              <span className="simulacao-form-header">Dados da fatura</span>
              <hr />
            </div>

            <div className="formLine">
              <Form.Group
                controlId="demandaPonta"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label disabled className="simulacao-form-title">
                  Demanda Contratada Ponta
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    name="demandaPonta"
                    disabled={formik.values.ths === "VERDE"}
                    placeholder="Em KW"
                    onChange={(e) => resetDiscountTypeWithDemandaPonta(e)}
                    onBlur={formik.handleBlur}
                    value={formik.values.demandaPonta}
                    isInvalid={!!formik.errors.demandaPonta}
                    required
                  />
                  <InputGroup.Text>kW</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.demandaPonta}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group
                controlId="demandaPontaUltrapassagem"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Demanda Ponta Ultrapassagem
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    disabled={formik.values.ths === "VERDE"}
                    name="demandaPontaUltrapassagem"
                    placeholder="Em KW"
                    onChange={(e) => resetDiscountWithUltrapassagemPontaType(e)}
                    onBlur={formik.handleBlur}
                    value={formik.values.demandaPontaUltrapassagem}
                    isInvalid={!!formik.errors.demandaPontaUltrapassagem}
                    required
                  />
                  <InputGroup.Text>kW</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.demandaPontaUltrapassagem}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group
                controlId="consumoPonta"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Consumo Ponta <span>*</span>
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    name="consumoPonta"
                    placeholder="Em kWh"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.consumoPonta}
                    isInvalid={!!formik.errors.consumoPonta}
                    required
                  />
                  <InputGroup.Text>kWh</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.consumoPonta}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>

            <div className="formLine">
              <Form.Group
                controlId="demandaForaPonta"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Demanda Contratada Fora de Ponta <span>*</span>
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    name="demandaForaPonta"
                    placeholder="Em kW"
                    onChange={(e) => resetDiscountTypeWithDemandaForaPonta(e)}
                    onBlur={formik.handleBlur}
                    value={formik.values.demandaForaPonta}
                    isInvalid={!!formik.errors.demandaForaPonta}
                    required
                  />
                  <InputGroup.Text>kW</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.demandaForaPonta}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group
                controlId="demandaForaPontaUltrapassagem"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Demanda Fora de Ponta Ultrapassagem
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    name="demandaForaPontaUltrapassagem"
                    placeholder="Em kW"
                    onChange={(e) =>
                      resetDiscounWithtUltrapassagemForaPontaType(e)
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.demandaForaPontaUltrapassagem}
                    isInvalid={!!formik.errors.demandaForaPontaUltrapassagem}
                    required
                  />
                  <InputGroup.Text>kW</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.demandaForaPontaUltrapassagem}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group
                controlId="consumoForaPonta"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Consumo Fora de Ponta <span>*</span>
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    name="consumoForaPonta"
                    placeholder="Em kWh"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.consumoForaPonta}
                    isInvalid={!!formik.errors.consumoForaPonta}
                    required
                  />
                  <InputGroup.Text>kWh</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.consumoForaPonta}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>

            {calc !== "MLXAPE" && (
              <>
                <div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="simulacao-form-header">
                      Dados de gerador
                    </span>
                    <span style={{ color: "#7e7e82" }}>
                      Caso não possua os dados do gerador, deixe os campos
                      abaixo em branco, que calcularemos uma estimativa
                    </span>
                  </div>

                  <hr />
                </div>

                <div className="formLine">
                  <Form.Group
                    controlId="gerador"
                    style={{
                      width: "100%",
                      maxWidth: "375px",
                      height: "80px",
                      display: "flex",
                      gap: "32px",
                    }}
                  >
                    <Form.Label className="simulacao-form-title">
                      Possui gerador?
                    </Form.Label>
                    <Form.Switch
                      type="switch"
                      name="isGerador"
                      label=""
                      value={formik.values.isGerador}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      checked={formik.values.isGerador}
                    />
                  </Form.Group>
                </div>

                {formik.values.isGerador && (
                  <div className="formLine">
                    <Form.Group
                      controlId="simulationCNPJ"
                      style={{
                        width: "100%",
                        maxWidth: "375px",
                        height: "80px",
                      }}
                    >
                      <Form.Label className="simulacao-form-title">
                        Consumo gerador
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          name="consumoGerador"
                          placeholder="Em kWh"
                          onBlur={formik.handleBlur}
                          onChange={(e) => resetGenerationConsume(e)}
                          value={formik.values.consumoGerador}
                          isInvalid={!!formik.errors.consumoGerador}
                        />
                        <InputGroup.Text>kWh</InputGroup.Text>
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.consumoGerador}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group
                      controlId="simulationCNPJ"
                      style={{
                        width: "100%",
                        maxWidth: "375px",
                        height: "80px",
                      }}
                    >
                      <Form.Label className="simulacao-form-title">
                        Custo diesel (R$/kWh)
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text>R$</InputGroup.Text>
                        <Form.Control
                          name="custoDiesel"
                          placeholder="Em reais"
                          onBlur={formik.handleBlur}
                          onChange={(e) => resetDieselCost(e)}
                          value={formik.values.custoDiesel}
                          isInvalid={!!formik.errors.custoDiesel}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.custoDiesel}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group
                      controlId="simulationCNPJ"
                      style={{
                        width: "100%",
                        maxWidth: "375px",
                        height: "80px",
                      }}
                    >
                      <Form.Label className="simulacao-form-title">
                        Custo gerador
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text>R$</InputGroup.Text>
                        <Form.Control
                          name="custoGerador"
                          placeholder="Em reais"
                          onBlur={formik.handleBlur}
                          onChange={(e) => resetGenerationCost(e)}
                          value={formik.values.custoGerador}
                          isInvalid={!!formik.errors.custoGerador}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.custoGerador}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </div>
                )}
              </>
            )}

            <div>
              <span className="simulacao-form-header">Dados de impostos</span>
              <hr />
            </div>

            <div className="formLine">
              <Form.Group
                controlId="icms"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  ICMS <span>*</span>
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    name="icms"
                    placeholder="Porcentagem"
                    onBlur={formik.handleBlur}
                    value={formik.values.icms}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.icms}
                    min={0}
                    max={100}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.icms}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group
                controlId="pis"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  PIS <span>*</span>
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    name="pis"
                    placeholder="Porcentagem"
                    onBlur={formik.handleBlur}
                    value={formik.values.pis}
                    onChange={formik.handleChange}
                    isInvalid={!!formik.errors.pis}
                    min={0}
                    max={100}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.pis}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group
                controlId="cofins"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  COFINS <span>*</span>
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    name="cofins"
                    placeholder="Porcentagem"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cofins}
                    isInvalid={!!formik.errors.cofins}
                    min={0}
                    max={100}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.cofins}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>

            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className="simulacao-form-header">
                  Dados do mercado livre
                </span>
                <span style={{ color: "#7e7e82" }}>
                  {parseInt(formik.values.demandaForaPonta) +
                    (Number.isNaN(
                      parseInt(formik.values.demandaForaPontaUltrapassagem)
                    )
                      ? 0
                      : parseInt(
                          formik.values.demandaForaPontaUltrapassagem
                        )) >=
                    500 ||
                  parseInt(formik.values.demandaPonta) +
                    (Number.isNaN(
                      parseInt(formik.values.demandaPontaUltrapassagem)
                    )
                      ? 0
                      : parseInt(formik.values.demandaPontaUltrapassagem)) >=
                    500 ||
                  buttonSelected !== "ML"
                    ? "Preços de energia recomendados para Atacadista"
                    : "Preços de energia recomendados para Varejista"}
                </span>
              </div>
              <hr />
              {energyWarning && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  O valor de energia que você está editando pode não refletir a
                  realidade atual do mercado, recomenda-se a curva de energia
                  gerada pelo nosso sistema
                </span>
              )}
            </div>

            <div className="formLine">
              <Form.Group
                controlId="simulationNumber"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Desconto <span>*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="desconto"
                  disabled={!demandaValid}
                  placeholder="Porcentagem"
                  onChange={(e) => {
                    calculateEnergyPrice(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.desconto}
                >
                  <option value={-1} hidden>
                    Selecione uma opção
                  </option>
                  <option value={0} hidden={buttonSelected !== "ML"}>
                    Convencional - sem desconto
                  </option>
                  <option value={0.5}>
                    Solar ou Eólica (I5) - 50% de desconto
                  </option>
                  <option value={1}>Biogás (I1) - 100% de desconto</option>
                </Form.Control>
                <span style={{ color: "red" }}>
                  {formik.values.demandaForaPonta === "" ||
                  formik.values.demandaForaPonta === "0"
                    ? "Primeiro informe a demanda contratada"
                    : formik.errors.desconto}
                </span>
              </Form.Group>

              <Form.Group
                controlId="precoEnergia1"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Preço da Energia 2025
                </Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>R$</InputGroup.Text>
                  <Form.Control
                    name="precoEnergia1"
                    disabled={formik.values.desconto === -1}
                    placeholder="Em reais"
                    onChange={(e) => resetEnergyPrice1(e)}
                    onBlur={formik.handleBlur}
                    value={formik.values.precoEnergia1}
                    isInvalid={!!formik.errors.precoEnergia1}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.precoEnergia1}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group
                controlId="precoEnergia2"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Preço da Energia 2026
                </Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>R$</InputGroup.Text>
                  <Form.Control
                    name="precoEnergia2"
                    disabled={formik.values.desconto === -1}
                    placeholder="Em reais"
                    onBlur={formik.handleBlur}
                    onChange={(e) => resetEnergyPrice2(e)}
                    value={formik.values.precoEnergia2}
                    isInvalid={!!formik.errors.precoEnergia2}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.precoEnergia2}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>

            <div className="formLine">
              <Form.Group
                controlId="precoEnergia3"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Preço da Energia 2027
                </Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>R$</InputGroup.Text>
                  <Form.Control
                    name="precoEnergia3"
                    disabled={formik.values.desconto === -1}
                    placeholder="Em reais"
                    onBlur={formik.handleBlur}
                    onChange={(e) => resetEnergyPrice3(e)}
                    value={formik.values.precoEnergia3}
                    isInvalid={!!formik.errors.precoEnergia3}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.precoEnergia3}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group
                controlId="precoEnergia4"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Preço da Energia 2028
                </Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>R$</InputGroup.Text>
                  <Form.Control
                    name="precoEnergia4"
                    disabled={formik.values.desconto === -1}
                    placeholder="Em reais"
                    onChange={(e) => resetEnergyPrice4(e)}
                    onBlur={formik.handleBlur}
                    value={formik.values.precoEnergia4}
                    isInvalid={!!formik.errors.precoEnergia4}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.precoEnergia4}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group
                controlId="precoEnergia5"
                style={{ width: "100%", maxWidth: "375px", height: "80px" }}
              >
                <Form.Label className="simulacao-form-title">
                  Preço da Energia 2029
                </Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>R$</InputGroup.Text>
                  <Form.Control
                    name="precoEnergia5"
                    disabled={formik.values.desconto === -1}
                    placeholder="Em reais"
                    onBlur={formik.handleBlur}
                    onChange={(e) => resetEnergyPrice5(e)}
                    value={formik.values.precoEnergia5}
                    isInvalid={!!formik.errors.precoEnergia5}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.precoEnergia5}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>

            <div className="formLine">
              <Form.Group
                controlId="isTaxCovid"
                style={{
                  width: "100%",
                  maxWidth: "375px",
                  height: "80px",
                  display: "flex",
                  gap: "32px",
                }}
              >
                <Form.Label className="simulacao-form-title">
                  Paga Taxa COVID?
                </Form.Label>
                <Form.Switch
                  type="switch"
                  name="isTaxCovid"
                  label=""
                  value={formik.values.isTaxCovid}
                  onBlur={formik.handleBlur}
                  onChange={(e) => resetCovid(e)}
                  checked={formik.values.isTaxCovid}
                />
              </Form.Group>

              <Form.Group
                controlId="isTaxScarcity"
                style={{
                  width: "100%",
                  maxWidth: "375px",
                  height: "80px",
                  display: "flex",
                  gap: "32px",
                }}
              >
                <Form.Label className="simulacao-form-title">
                  Paga Taxa escassez?
                </Form.Label>
                <Form.Switch
                  type="switch"
                  name="isTaxScarcity"
                  label=""
                  value={formik.values.isTaxScarcity}
                  onBlur={formik.handleBlur}
                  onChange={(e) => resetScarcity(e)}
                  checked={formik.values.isTaxScarcity}
                />
              </Form.Group>

              <Form.Group
                controlId="isEncargos"
                style={{
                  width: "100%",
                  maxWidth: "375px",
                  height: "80px",
                  display: "flex",
                  gap: "32px",
                }}
              >
                <Form.Label className="simulacao-form-title">
                  Paga encargos?
                </Form.Label>
                <Form.Switch
                  type="switch"
                  name="isEncargos"
                  label=""
                  value={formik.values.isEncargos}
                  onBlur={formik.handleBlur}
                  onChange={(e) => resetEncargos(e)}
                  checked={formik.values.isEncargos}
                />
              </Form.Group>
            </div>

            {(calc === "APE" || calc === "MLXAPE") && (
              <>
                <div>
                  <span className="simulacao-form-header">
                    Dados para simular autoprodutor de energia
                  </span>
                  <hr />
                </div>

                <div className="formLine">
                  <Form.Group
                    controlId="simulationLocal"
                    style={{
                      width: "100%",
                      maxWidth: "375px",
                      height: "80px",
                      display: "flex",
                      gap: "32px",
                    }}
                  >
                    <Form.Label className="simulacao-form-title">
                      Local de Instalação
                    </Form.Label>
                    <Form.Switch
                      type="switch"
                      name="local"
                      label="É usina local?"
                      value={formik.values.local}
                      onBlur={formik.handleBlur}
                      onChange={(e) => resetSimultaneidade(e)}
                      checked={formik.values.local}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="simulationCNPJ"
                    style={{
                      width: "100%",
                      maxWidth: "375px",
                      height: "80px",
                    }}
                  >
                    <Form.Label className="simulacao-form-title">
                      Fator de Simultaneidade <span>*</span>
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        name="simultaneidade"
                        placeholder="Porcentagem"
                        onBlur={formik.handleBlur}
                        value={formik.values.simultaneidade}
                        onChange={formik.handleChange}
                        isInvalid={!!formik.errors.simultaneidade}
                        min={0}
                        max={100}
                        required={buttonSelected !== "ML"}
                        disabled={!formik.values.local}
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.simultaneidade}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    controlId="simulationCNPJ"
                    style={{ width: "100%", maxWidth: "375px", height: "80px" }}
                  >
                    <Form.Label className="simulacao-form-title">
                      Geração <span>*</span>
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        name="geracao"
                        placeholder="Em kWh"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.geracao}
                        isInvalid={!!formik.errors.geracao}
                        required={buttonSelected !== "ML"}
                      />
                      <InputGroup.Text>kWh</InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.geracao}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="formLine">
                  <Form.Group
                    controlId="simulationCNPJ"
                    style={{ width: "100%", maxWidth: "375px", height: "80px" }}
                  >
                    <Form.Label className="simulacao-form-title">
                      Demanda da usina <span>*</span>
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        name="demandaUsina"
                        placeholder="Em kW"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.demandaUsina}
                        isInvalid={!!formik.errors.demandaUsina}
                        required={buttonSelected !== "ML"}
                      />
                      <InputGroup.Text>kW</InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.demandaUsina}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    controlId="simulationCNPJ"
                    style={{ width: "100%", maxWidth: "375px", height: "80px" }}
                  >
                    <Form.Label className="simulacao-form-title">
                      Potência da Usina <span>*</span>
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        name="potenciaUsina"
                        placeholder="Em kWp"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.potenciaUsina}
                        isInvalid={!!formik.errors.potenciaUsina}
                        required={buttonSelected !== "ML"}
                      />
                      <InputGroup.Text>kWp</InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.potenciaUsina}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    controlId="simulationCNPJ"
                    style={{ width: "100%", maxWidth: "375px", height: "80px" }}
                  >
                    <Form.Label className="simulacao-form-title">
                      Investimento <span>*</span>
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>R$</InputGroup.Text>
                      <Form.Control
                        name="custoProjeto"
                        placeholder="Em reais"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.custoProjeto}
                        isInvalid={!!formik.errors.custoProjeto}
                        required={buttonSelected !== "ML"}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.custoProjeto}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </div>

                {proposalPermission && (
                  <>
                    <div>
                      <span className="simulacao-form-header">
                        Dados para gerar proposta de UFV
                      </span>
                      <hr />
                    </div>

                    <div className="formLine">
                      <Form.Group
                        controlId="generateProposal"
                        style={{
                          width: "100%",
                          maxWidth: "375px",
                          height: "80px",
                          display: "flex",
                          gap: "32px",
                        }}
                      >
                        <Form.Label className="simulacao-form-title">
                          Gerar proposta?
                        </Form.Label>
                        <Form.Switch
                          type="switch"
                          name="generateProposal"
                          label=""
                          value={formik.values.generateProposal}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          checked={formik.values.generateProposal}
                        />
                      </Form.Group>

                      {formik.values.generateProposal && (
                        <>
                          <Form.Group
                            controlId="taxaAtratividade"
                            style={{
                              width: "100%",
                              maxWidth: "375px",
                              height: "80px",
                            }}
                          >
                            <Form.Label className="simulacao-form-title">
                              Taxa de atratividade <span>*</span>
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                name="taxaAtratividade"
                                placeholder="Porcentagem"
                                onBlur={formik.handleBlur}
                                value={formik.values.taxaAtratividade}
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.taxaAtratividade}
                                min={0}
                                max={100}
                              />
                              <InputGroup.Text>%</InputGroup.Text>
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.taxaAtratividade}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>

                          <Form.Group
                            controlId="lcoe"
                            style={{
                              width: "100%",
                              maxWidth: "375px",
                              height: "80px",
                            }}
                          >
                            <Form.Label className="simulacao-form-title">
                              LCOE <span>*</span>
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text>R$/MWh</InputGroup.Text>
                              <Form.Control
                                name="lcoe"
                                placeholder="Em R$/MWh"
                                onBlur={formik.handleBlur}
                                value={formik.values.lcoe}
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.lcoe}
                                min={0}
                                max={100}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.lcoe}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                        </>
                      )}
                    </div>

                    {formik.values.generateProposal && (
                      <>
                        <div className="formLine">
                          <Form.Group
                            controlId="clientLogo"
                            style={{
                              width: "100%",
                              maxWidth: "375px",
                              height: "80px",
                              display: "flex",
                              gap: "32px",
                            }}
                          >
                            <Form.File
                              type="file"
                              name="clientLogo"
                              accept="image/*"
                              label="Upload logo do cliente"
                              onBlur={formik.handleBlur}
                              onChange={(e) =>
                                handleFileChange(e, "clientLogo")
                              }
                            />
                          </Form.Group>

                          <Form.Group
                            controlId="partnerLogo"
                            style={{
                              width: "100%",
                              maxWidth: "375px",
                              height: "80px",
                              display: "flex",
                              gap: "32px",
                            }}
                          >
                            <Form.File
                              type="file"
                              name="partnerLogo"
                              accept="image/*"
                              label="Upload logo do parceiro"
                              onBlur={formik.handleBlur}
                              onChange={(e) =>
                                handleFileChange(e, "partnerLogo")
                              }
                            />
                          </Form.Group>

                          <Form.Group
                            controlId="simulationLocal"
                            style={{
                              width: "100%",
                              maxWidth: "375px",
                              height: "80px",
                              display: "flex",
                              gap: "32px",
                            }}
                          >
                            <Form.File
                              type="file"
                              name="projectImage1"
                              accept="image/*"
                              label="Upload imagem da disposição dos módulos"
                              onBlur={formik.handleBlur}
                              onChange={(e) =>
                                handleFileChange(e, "projectImage1")
                              }
                            />
                          </Form.Group>

                          <Form.Group
                            controlId="simulationLocal"
                            style={{
                              width: "100%",
                              maxWidth: "375px",
                              height: "80px",
                              display: "flex",
                              gap: "32px",
                            }}
                          >
                            <Form.File
                              type="file"
                              name="projectImage2"
                              accept="image/*"
                              label="Upload imagem do mapa de localização"
                              onBlur={formik.handleBlur}
                              onChange={(e) =>
                                handleFileChange(e, "projectImage2")
                              }
                            />
                          </Form.Group>
                        </div>

                        <div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span className="simulacao-form-header">
                              Dados técnicos do projeto
                            </span>
                            <span style={{ color: "#7e7e82" }}>
                              Lembre de pular linhas para formatar melhor o PDF
                            </span>
                            <hr />
                          </div>
                        </div>

                        <div className="formLine">
                          <Form.Group
                            controlId="localization"
                            style={{
                              width: "100%",
                              maxWidth: "375px",
                              minHeight: "80px",
                            }}
                          >
                            <Form.Label className="simulacao-form-title">
                              Localização <span>*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="localization"
                              placeholder="Digite a(s) localização(ões) do projeto"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.localization}
                              isInvalid={!!formik.errors.localization}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.localization}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            controlId="modules"
                            style={{
                              width: "100%",
                              maxWidth: "375px",
                              minHeight: "80px",
                            }}
                          >
                            <Form.Label className="simulacao-form-title">
                              Módulos <span>*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="modules"
                              placeholder="Digite o(s) módulo(s) do projeto"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.modules}
                              isInvalid={!!formik.errors.modules}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.modules}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            controlId="inverter"
                            style={{
                              width: "100%",
                              maxWidth: "375px",
                              minHeight: "80px",
                            }}
                          >
                            <Form.Label className="simulacao-form-title">
                              Inversores <span>*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="inverter"
                              placeholder="Digite o(s) inversor(es) do projeto"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.inverter}
                              isInvalid={!!formik.errors.inverter}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.inverter}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            controlId="structure"
                            style={{
                              width: "100%",
                              maxWidth: "375px",
                              minHeight: "80px",
                            }}
                          >
                            <Form.Label className="simulacao-form-title">
                              Estrutura da montagem <span>*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="structure"
                              placeholder="Digite a(s) estrutura(s) de montagem do projeto"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.structure}
                              isInvalid={!!formik.errors.structure}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.structure}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <div>
                          <span className="simulacao-form-header">
                            Tipos de instalação
                          </span>
                          <hr />
                        </div>

                        {formik.values.items.length > 0 ? (
                          formik.values.items.map((item, index) => (
                            <div key={index}>
                              <div className="formLine">
                                <Form.Group
                                  controlId="nome"
                                  style={{
                                    width: "100%",
                                    maxWidth: "375px",
                                    height: "80px",
                                  }}
                                >
                                  <Form.Label className="simulacao-form-title">
                                    Nome da instalação <span>*</span>
                                  </Form.Label>
                                  <Form.Control
                                    name="nome"
                                    placeholder="Nome da instalação"
                                    onBlur={formik.handleBlur}
                                    value={item.nome}
                                    onChange={(event) =>
                                      handleItemChange(index, event)
                                    }
                                    isInvalid={
                                      !!formik.errors.items?.[index]?.nome
                                    }
                                    required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.items?.[index]?.nome}
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                  controlId="potencia"
                                  style={{
                                    width: "100%",
                                    maxWidth: "375px",
                                    height: "80px",
                                  }}
                                >
                                  <Form.Label className="simulacao-form-title">
                                    Potência da instalação <span>*</span>
                                  </Form.Label>
                                  <InputGroup className="mb-3">
                                    <Form.Control
                                      name="potencia"
                                      placeholder="Em MWp"
                                      onBlur={formik.handleBlur}
                                      value={item.potencia}
                                      onChange={(event) =>
                                        handleItemChange(index, event)
                                      }
                                      isInvalid={
                                        !!formik.errors.items?.[index]?.potencia
                                      }
                                      required
                                    />
                                    <InputGroup.Text>MWp</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                      {formik.errors.items?.[index]?.potencia}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>

                                <Form.Group
                                  controlId="geradorFotovoltaico"
                                  style={{
                                    width: "100%",
                                    maxWidth: "375px",
                                    height: "80px",
                                  }}
                                >
                                  <Form.Label className="simulacao-form-title">
                                    Gerador fotovoltaico <span>*</span>
                                  </Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>R$/Wp</InputGroup.Text>
                                    <Form.Control
                                      name="geradorFotovoltaico"
                                      placeholder="Em R$/Wp"
                                      onBlur={formik.handleBlur}
                                      value={item.geradorFotovoltaico}
                                      onChange={(event) =>
                                        handleItemChange(index, event)
                                      }
                                      isInvalid={
                                        !!formik.errors.items?.[index]
                                          ?.geradorFotovoltaico
                                      }
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {
                                        formik.errors.items?.[index]
                                          ?.geradorFotovoltaico
                                      }
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>

                                <Form.Group
                                  controlId="projetoHomologacao"
                                  style={{
                                    width: "100%",
                                    maxWidth: "375px",
                                    height: "80px",
                                  }}
                                >
                                  <Form.Label className="simulacao-form-title">
                                    Projeto e homologação <span>*</span>
                                  </Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>R$/Wp</InputGroup.Text>
                                    <Form.Control
                                      name="projetoHomologacao"
                                      placeholder="Em R$/Wp"
                                      onBlur={formik.handleBlur}
                                      value={item.projetoHomologacao}
                                      onChange={(event) =>
                                        handleItemChange(index, event)
                                      }
                                      isInvalid={
                                        !!formik.errors.items?.[index]
                                          ?.projetoHomologacao
                                      }
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {
                                        formik.errors.items?.[index]
                                          ?.projetoHomologacao
                                      }
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>

                                <Form.Group
                                  controlId="epc"
                                  style={{
                                    width: "100%",
                                    maxWidth: "375px",
                                    height: "80px",
                                  }}
                                >
                                  <Form.Label className="simulacao-form-title">
                                    EPC <span>*</span>
                                  </Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>R$/Wp</InputGroup.Text>
                                    <Form.Control
                                      name="epc"
                                      placeholder="Em R$/Wp"
                                      onBlur={formik.handleBlur}
                                      value={item.epc}
                                      onChange={(event) =>
                                        handleItemChange(index, event)
                                      }
                                      isInvalid={
                                        !!formik.errors.items?.[index]?.epc
                                      }
                                      required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {formik.errors.items?.[index]?.epc}
                                    </Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Button
                                    variant="danger"
                                    onClick={() => removeItem(index)}
                                    style={{
                                      marginLeft: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Remover
                                  </Button>
                                </div>
                              </div>

                              <hr />
                            </div>
                          ))
                        ) : (
                          <div style={{ color: "red" }}>
                            Nenhuma instalação adicionada
                          </div>
                        )}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="primary"
                            onClick={addItem}
                            style={{ marginTop: "10px" }}
                          >
                            Adicionar Item
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {calc === "GDXAPE" && (
              <>
                <div>
                  <span className="simulacao-form-header">
                    Dados da usina de geração distribuída
                  </span>
                  <hr />
                </div>

                <div className="formLine">
                  <Form.Group
                    controlId="simulationLocal"
                    style={{
                      width: "100%",
                      maxWidth: "375px",
                      height: "80px",
                      display: "flex",
                      gap: "32px",
                    }}
                  >
                    <Form.Label className="simulacao-form-title">
                      Local de Instalação
                    </Form.Label>
                    <Form.Switch
                      type="switch"
                      name="local"
                      label="É usina local?"
                      value={formik.values.local}
                      onBlur={formik.handleBlur}
                      onChange={(e) => resetSimultaneidade(e)}
                      checked={formik.values.local}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="abateICMS"
                    style={{
                      width: "100%",
                      maxWidth: "375px",
                      height: "80px",
                      display: "flex",
                      gap: "32px",
                    }}
                  >
                    <Form.Label className="simulacao-form-title">
                      A energia injetada compensa o ICMS?
                    </Form.Label>
                    <Form.Switch
                      type="switch"
                      name="abateICMS"
                      label=""
                      value={formik.values.abateICMS}
                      onBlur={formik.handleBlur}
                      onChange={(e) => resetAbateICMS(e)}
                      checked={formik.values.abateICMS}
                    />
                  </Form.Group>
                </div>

                <div className="formLine">
                  <Form.Group
                    controlId="simulationCNPJ"
                    style={{ width: "100%", maxWidth: "375px", height: "80px" }}
                  >
                    <Form.Label className="simulacao-form-title">
                      Energia Injetada <span>*</span>
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        name="geracao"
                        placeholder="Em kWh"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.geracao}
                        isInvalid={!!formik.errors.geracao}
                        required={buttonSelected !== "ML"}
                      />
                      <InputGroup.Text>kWh</InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.geracao}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    controlId="simulationCNPJ"
                    style={{ width: "100%", maxWidth: "375px", height: "80px" }}
                  >
                    <Form.Label className="simulacao-form-title">
                      Demanda da usina <span>*</span>
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        name="demandaUsina"
                        placeholder="Em kW"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.demandaUsina}
                        isInvalid={!!formik.errors.demandaUsina}
                        required={buttonSelected !== "ML"}
                      />
                      <InputGroup.Text>kW</InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.demandaUsina}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group
                    controlId="simulationCNPJ"
                    style={{ width: "100%", maxWidth: "375px", height: "80px" }}
                  >
                    <Form.Label className="simulacao-form-title">
                      Potência da Usina <span>*</span>
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        name="potenciaUsina"
                        placeholder="Em kWp"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.potenciaUsina}
                        isInvalid={!!formik.errors.potenciaUsina}
                        required={buttonSelected !== "ML"}
                      />
                      <InputGroup.Text>kWp</InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.potenciaUsina}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </div>
              </>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {selectedFile && (
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "#7e7e82",
                    border: "1px",
                    borderRadius: "16px",
                    padding: "6px 12px",
                    width: "max-content",
                    margin: "5px auto",
                    fontSize: 13.5,
                  }}
                >
                  {selectedFile.name}
                </span>
              )}

              {!userPermission ? (
                <div className="send-invoice-area" translate="no">
                  <span className="send-btn" style={{ textAlign: "center" }}>
                    SELECIONE A FATURA
                    <input
                      className="input-file"
                      type="file"
                      multiple
                      onChange={handleFileSelect}
                      accept="application/pdf"
                    />
                  </span>

                  <button
                    disabled={fileLoading || selectedFile === null}
                    style={{ border: 0 }}
                    className={
                      !fileLoading
                        ? "send-btn-button simulacao-submit"
                        : "button-loading"
                    }
                    type="submit"
                  >
                    {!fileLoading ? (
                      "SIMULAR ECONOMIA"
                    ) : (
                      <Spinner animation="border" variant="primary"></Spinner>
                    )}
                    {!selectedFile && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip">
                            É necessário enviar a fatura para fazer a simulação
                          </Tooltip>
                        }
                        placement="bottom"
                        delayShow={300}
                        delayHide={150}
                      >
                        <div
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                            width: "100%",
                            height: "100%",
                          }}
                        ></div>
                      </OverlayTrigger>
                    )}
                  </button>
                </div>
              ) : (
                <button
                  className="simulacao-button-selected simulacao-submit"
                  type="submit"
                >
                  SIMULAR ECONOMIA
                </button>
              )}

              {showAlert && (
                <div className="simulacao-error">
                  <Alert
                    variant="danger"
                    onClose={() => setShowAlert(false)}
                    dismissible
                  >
                    Erro, verifique se todos os dados estão preenchidos
                    corretamente e tente novamente!
                  </Alert>
                </div>
              )}

              {loading && (
                <div className="simulacao-error">
                  <Spinner animation="border" variant="primary"></Spinner>
                </div>
              )}
            </div>
          </Form>
        ) : (
          <Accordion>
            <div style={{ width: "100%" }}>
              <Accordion.Toggle
                onClick={() => handleViewData()}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100px",
                  alignItems: "center",
                  border: "0",
                  padding: "0 20px",
                  backgroundColor: "#F5F5F5",
                }}
                eventKey="0"
              >
                <div className="simulacao-dados-left">
                  <div
                    className="simulacao-editar-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSimulacaoSelected("");
                      setViewSimulacaoData(false);
                    }}
                  >
                    <img src={arrowleft} alt="voltar icon" />
                    <h1>EDITAR</h1>
                  </div>
                  <span>
                    {simulacaoSelected === "ML"
                      ? "Simulando mercado livre"
                      : simulacaoSelected === "APE"
                      ? "Simulando autoprodutor de energia"
                      : simulacaoSelected === "MLXAPE"
                      ? "Simulando comparação mercado livre x autoprodutor"
                      : simulacaoSelected === "GDXAPE"
                      ? "Simulando comparação geração distribuída x autoprodutor"
                      : ""}
                  </span>
                </div>
                <div className="simulacao-voltar">
                  {viewSimulacaoData ? (
                    <>
                      <span className="simulacao-dados-button">
                        ESCONDER DADOS
                      </span>
                      <img src={arrowup} alt="voltar icon" />
                    </>
                  ) : (
                    <>
                      <span className="simulacao-dados-button">VER DADOS</span>
                      <img src={arrowdown} alt="voltar icon" />
                    </>
                  )}
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <div className="simulacao-accordion-container">
                  <div>
                    <span className="simulacao-form-header">
                      Dados do cliente
                    </span>
                    <hr />
                  </div>

                  <div className="formLine-data">
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">CNPJ da Empresa</h1>
                      <h2 className="simulacao-form-description">
                        {formik.values.cnpj}
                      </h2>
                    </div>
                    {formik.values.nomeCliente !== "" ? (
                      <div className="simulacao-data-flex">
                        <h1 className="simulacao-form-title">
                          Nome do cliente
                        </h1>
                        <h2 className="simulacao-form-description">
                          {formik.values.nomeCliente}
                        </h2>
                      </div>
                    ) : (
                      <div className="simulacao-data-flex">
                        <h1 className="simulacao-form-title">
                          Nome do cliente
                        </h1>
                        <h2 className="simulacao-form-description">-</h2>
                      </div>
                    )}
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">
                        Custo Operacional e CCEE
                      </h1>
                      <h2 className="simulacao-form-description">
                        {simulacaoSelected === "ML"
                          ? livreData.gestao.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : apeData.gestao.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                      </h2>
                    </div>
                  </div>

                  <div className="formLine-data">
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">Distribuidora</h1>
                      <h2 className="simulacao-form-description">
                        {formik.values.distribuidora}
                      </h2>
                    </div>
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">THS</h1>
                      <h2 className="simulacao-form-description">
                        {formik.values.ths}
                      </h2>
                    </div>
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">Grupo Tarifário</h1>
                      <h2 className="simulacao-form-description">
                        {formik.values.grupoTarifario}
                      </h2>
                    </div>
                  </div>

                  <div>
                    <span className="simulacao-form-header">
                      Dados da fatura
                    </span>
                    <hr />
                  </div>

                  <div className="formLine-data">
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">
                        Demanda contratada P (kW)
                      </h1>
                      <h2 className="simulacao-form-description">
                        {formik.values.demandaPonta} kW
                      </h2>
                    </div>
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">
                        Demanda Ultrapassagem P (kW)
                      </h1>
                      <h2 className="simulacao-form-description">
                        {+formik.values.demandaPontaUltrapassagem.replace(
                          ",",
                          "."
                        ) > 0
                          ? formik.values.demandaPontaUltrapassagem
                          : 0}{" "}
                        kW
                      </h2>
                    </div>

                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">Consumo P (kWh)</h1>
                      <h2 className="simulacao-form-description">
                        {formik.values.consumoPonta} kWh
                      </h2>
                    </div>
                  </div>

                  <div className="formLine-data">
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">
                        Demanda contratada FP (kW)
                      </h1>
                      <h2 className="simulacao-form-description">
                        {formik.values.demandaForaPonta} kW
                      </h2>
                    </div>
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">
                        Demanda Ultrapassagem FP (kW)
                      </h1>
                      <h2 className="simulacao-form-description">
                        {+formik.values.demandaForaPontaUltrapassagem.replace(
                          ",",
                          "."
                        ) > 0
                          ? formik.values.demandaForaPontaUltrapassagem
                          : 0}{" "}
                        kW
                      </h2>
                    </div>
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">Consumo FP (kWh)</h1>
                      <h2 className="simulacao-form-description">
                        {formik.values.consumoForaPonta} kWh
                      </h2>
                    </div>
                  </div>

                  {formik.values.isGerador && (
                    <>
                      <div>
                        <span className="simulacao-form-header">
                          Dados de gerador
                        </span>
                        <hr />
                      </div>

                      <div className="formLine-data">
                        <div className="simulacao-data-flex">
                          <h1 className="simulacao-form-title">
                            Consumo gerador (kWh)
                          </h1>
                          <h2 className="simulacao-form-description">
                            {formik.values.consumoGerador
                              ? `${formik.values.consumoGerador} kWh`
                              : ""}
                          </h2>
                        </div>

                        <div className="simulacao-data-flex">
                          <h1 className="simulacao-form-title">
                            Custo diesel (R$/kWh)
                          </h1>
                          <h2 className="simulacao-form-description">
                            {formik.values.custoDiesel
                              ? (+formik.values.custoDiesel.replace(
                                  ",",
                                  "."
                                )).toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })
                              : ""}
                          </h2>
                        </div>

                        <div className="simulacao-data-flex">
                          <h1 className="simulacao-form-title">
                            Custo gerador
                          </h1>
                          <h2 className="simulacao-form-description">
                            {formik.values.custoGerador
                              ? (+formik.values.custoGerador.replace(
                                  ",",
                                  "."
                                )).toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })
                              : ""}
                          </h2>
                        </div>
                      </div>
                    </>
                  )}

                  <div>
                    <span className="simulacao-form-header">
                      Dados de impostos
                    </span>
                    <hr />
                  </div>

                  <div className="formLine-data">
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">ICMS</h1>
                      <h2 className="simulacao-form-description">
                        {formik.values.icms}%
                      </h2>
                    </div>
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">PIS</h1>
                      <h2 className="simulacao-form-description">
                        {formik.values.pis}%
                      </h2>
                    </div>
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">COFINS</h1>
                      <h2 className="simulacao-form-description">
                        {formik.values.cofins}%
                      </h2>
                    </div>
                  </div>

                  <div>
                    <span className="simulacao-form-header">
                      Dados do mercado livre
                    </span>
                    <hr />
                  </div>

                  <div className="formLine-data">
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">Desconto</h1>
                      <h2 className="simulacao-form-description">
                        {formik.values.desconto * 100}%
                      </h2>
                    </div>
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">
                        Preço energia 2025
                      </h1>
                      <h2 className="simulacao-form-description">
                        {(+formik.values.precoEnergia1.replace(
                          ",",
                          "."
                        )).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </h2>
                    </div>
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">
                        Preço energia 2026
                      </h1>
                      <h2 className="simulacao-form-description">
                        {(+formik.values.precoEnergia2.replace(
                          ",",
                          "."
                        )).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </h2>
                    </div>
                  </div>

                  <div className="formLine-data">
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">
                        Preço energia 2027
                      </h1>
                      <h2 className="simulacao-form-description">
                        {(+formik.values.precoEnergia3.replace(
                          ",",
                          "."
                        )).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </h2>
                    </div>
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">
                        Preço energia 2028
                      </h1>
                      <h2 className="simulacao-form-description">
                        {(+formik.values.precoEnergia4.replace(
                          ",",
                          "."
                        )).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </h2>
                    </div>
                    <div className="simulacao-data-flex">
                      <h1 className="simulacao-form-title">
                        Preço energia 2029
                      </h1>
                      <h2 className="simulacao-form-description">
                        {(+formik.values.precoEnergia5.replace(
                          ",",
                          "."
                        )).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </h2>
                    </div>
                  </div>

                  {(simulacaoSelected === "APE" ||
                    simulacaoSelected === "MLXAPE") && (
                    <>
                      <div>
                        <span className="simulacao-form-header">
                          Dados de autoprodutor de energia
                        </span>
                        <hr />
                      </div>

                      <div className="formLine-data">
                        <div className="simulacao-data-flex">
                          <h1 className="simulacao-form-title">
                            Local da instalação
                          </h1>
                          <h2 className="simulacao-form-description">
                            {formik.values.local
                              ? "Usina local"
                              : "Não é usina local"}
                          </h2>
                        </div>
                        {formik.values.local && (
                          <div className="simulacao-data-flex">
                            <h1 className="simulacao-form-title">
                              Fator de simultaneidade
                            </h1>
                            <h2 className="simulacao-form-description">
                              {formik.values.simultaneidade}%
                            </h2>
                          </div>
                        )}
                        <div className="simulacao-data-flex">
                          <h1 className="simulacao-form-title">
                            Geração (kWh)
                          </h1>
                          <h2 className="simulacao-form-description">
                            {formik.values.geracao} kWh
                          </h2>
                        </div>
                      </div>
                      <div className="formLine-data">
                        <div className="simulacao-data-flex">
                          <h1 className="simulacao-form-title">
                            Demanda da usina (kW)
                          </h1>
                          <h2 className="simulacao-form-description">
                            {formik.values.demandaUsina} kW
                          </h2>
                        </div>
                        <div className="simulacao-data-flex">
                          <h1 className="simulacao-form-title">
                            Potência da usina (kWp)
                          </h1>
                          <h2 className="simulacao-form-description">
                            {formik.values.potenciaUsina} kWp
                          </h2>
                        </div>
                        <div className="simulacao-data-flex">
                          <h1 className="simulacao-form-title">Investimento</h1>
                          <h2 className="simulacao-form-description">
                            {(+formik.values.custoProjeto.replace(
                              ",",
                              "."
                            )).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </h2>
                        </div>
                      </div>
                    </>
                  )}

                  {simulacaoSelected === "GDXAPE" && (
                    <>
                      <div>
                        <span className="simulacao-form-header">
                          Dados da usina de geração distribuída
                        </span>
                        <hr />
                      </div>

                      <div className="formLine-data">
                        <div className="simulacao-data-flex">
                          <h1 className="simulacao-form-title">
                            Local da instalação
                          </h1>
                          <h2 className="simulacao-form-description">
                            {formik.values.local
                              ? "Usina local"
                              : "Não é usina local"}
                          </h2>
                        </div>

                        <div className="simulacao-data-flex">
                          <h1 className="simulacao-form-title">
                            Energia Injetada (kWh)
                          </h1>
                          <h2 className="simulacao-form-description">
                            {formik.values.geracao} kWh
                          </h2>
                        </div>

                        <div className="simulacao-data-flex">
                          <h1 className="simulacao-form-title">
                            Demanda da usina (kW)
                          </h1>
                          <h2 className="simulacao-form-description">
                            {formik.values.demandaUsina} kW
                          </h2>
                        </div>

                        <div className="simulacao-data-flex">
                          <h1 className="simulacao-form-title">
                            Potência da usina (kWp)
                          </h1>
                          <h2 className="simulacao-form-description">
                            {formik.values.potenciaUsina} kWp
                          </h2>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
        )}

        <br />

        <div id="simulation-section" className="table-tarifa-container">
          {simulacaoSelected !== "" ? (
            <>
              <div className="simulacao-header-title">
                <h1>Economia mensal</h1>
              </div>

              {simulacaoSelected === "ML" ? (
                <LivreTable
                  cativoData={cativoData}
                  livreData={livreData}
                  economia={economyData.economyData[0]?.economia}
                />
              ) : simulacaoSelected === "APE" ? (
                <APETable
                  cativoData={cativoData}
                  apeData={apeData}
                  economia={economyData.economyData[0].economia}
                />
              ) : simulacaoSelected === "MLXAPE" ? (
                <APEXLivreTable
                  livreData={livreData}
                  apeData={apeData}
                  economia={economyData}
                />
              ) : simulacaoSelected === "GDXAPE" ? (
                <GDXAPETable
                  gdData={gdData}
                  apeData={apeData}
                  economia={economyData}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {economyData?.economyData?.length > 0 &&
            simulacaoSelected !== "MLXAPE" &&
            simulacaoSelected !== "" && (
              <>
                <div className="simulacao-header-title">
                  <h1>Economia anual</h1>
                  <h2>
                    Veja a projeção de economia anual com o reajuste das tarifas
                    e com o preço de energia atualizada para os próximos anos
                  </h2>
                </div>
                {economyData.economyData.map(
                  (e, i) =>
                    i < 5 &&
                    (simulacaoSelected === "ML" ? (
                      <>
                        <div style={{ marginTop: "16px" }}>
                          <span className="simulacao-form-header">{e.ano}</span>
                          <hr />
                        </div>

                        <div className="resultadoSimulacaoContainer2" key={i}>
                          <div className="economia-card2">
                            <h1>Custo cativo</h1>
                            <h2>
                              {e.totalCativo.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </h2>
                          </div>

                          <div className="economia-card2">
                            <h1>Custo livre</h1>
                            <h2>
                              {e.totalLivre.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </h2>
                          </div>

                          <div className="economia-card2">
                            <h1>Economia mensal</h1>
                            <div className="economia-value">
                              <h2>
                                {e.economia.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </h2>
                              <h3
                                className={
                                  e.economia >= 0
                                    ? "economia-positivo"
                                    : "economia-negativo"
                                }
                              >
                                {e.economiaPct.toLocaleString("pt-BR", {
                                  style: "percent",
                                })}
                              </h3>
                            </div>
                          </div>

                          <div className="economia-card2">
                            <h1>Economia anual</h1>
                            <div className="economia-value">
                              <h2>
                                {e.economiaAnual.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : simulacaoSelected === "GDXAPE" ? (
                      <>
                        <div style={{ marginTop: "16px" }}>
                          <span className="simulacao-form-header">{e.ano}</span>
                          <hr />
                        </div>

                        <div className="resultadoSimulacaoContainer2" key={i}>
                          <div className="economia-card2">
                            <h1>Custo GD</h1>
                            <h2>
                              {e.totalGD.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </h2>
                          </div>

                          <div className="economia-card2">
                            <h1>Custo livre</h1>
                            <h2>
                              {e.totalAPE.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </h2>
                          </div>

                          <div className="economia-card2">
                            <h1>Economia mensal</h1>
                            <div className="economia-value">
                              <h2>
                                {e.economia.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </h2>
                              <h3
                                className={
                                  e.economia >= 0
                                    ? "economia-positivo"
                                    : "economia-negativo"
                                }
                              >
                                {e.economiaPct.toLocaleString("pt-BR", {
                                  style: "percent",
                                })}
                              </h3>
                            </div>
                          </div>

                          <div className="economia-card2">
                            <h1>Economia anual</h1>
                            <div className="economia-value">
                              <h2>
                                {e.economiaAnual.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ marginTop: "16px" }}>
                          <span className="simulacao-form-header">{e.ano}</span>
                          <hr />
                        </div>

                        <div className="resultadoSimulacaoContainer2" key={i}>
                          <div className="economia-card2">
                            <h1>Custo cativo</h1>
                            <h2>
                              {e.totalCativo.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </h2>
                          </div>

                          <div className="economia-card2">
                            <h1>Custo autoprodutor</h1>
                            <h2>
                              {e.totalAPE.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </h2>
                          </div>

                          <div className="economia-card2">
                            <h1>Economia mensal</h1>
                            <div className="economia-value">
                              <h2>
                                {e.economia.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </h2>
                              <h3
                                className={
                                  e.economia >= 0
                                    ? "economia-positivo"
                                    : "economia-negativo"
                                }
                              >
                                {e.economiaPct.toLocaleString("pt-BR", {
                                  style: "percent",
                                })}
                              </h3>
                            </div>
                          </div>

                          <div className="economia-card2">
                            <h1>Economia anual</h1>
                            <div className="economia-value">
                              <h2>
                                {e.economiaAnual.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                )}

                {simulacaoSelected === "APE" ||
                simulacaoSelected === "APEXML" ? (
                  <>
                    <div className="simulacao-header-title">
                      <h1>Projeções autoprodutor</h1>
                      {/* <h2>Veja a projeção de VPL, TIR e payback do projeto</h2> */}
                      <h2>
                        Veja a projeção de payback do projeto e o gráfico com o
                        fluxo de caixa anual, utilizando o valor presente, com
                        os reajustes de IPCA e conexão inclusos
                      </h2>
                    </div>
                    <div className="resultadoSimulacaoContainer">
                      <TIRPaybackTable
                        tir={economyData.tir}
                        vpl10anos={
                          economyData.vplAnual.slice(0, 11)[
                            economyData.vplAnual.slice(0, 11).length - 1
                          ]
                        }
                        vpl30anos={
                          economyData.vplAnual[economyData.vplAnual.length - 1]
                        }
                        paybackAnual={economyData.payBackAnual}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="simulacao-header-title">
                      <h1>Projeções</h1>
                      <h2>
                        Gráfico com as economias acumuladas de cada ano, já com
                        os reajustes de IPCA e conexão
                      </h2>
                    </div>
                  </>
                )}
                {simulacaoSelected === "ML" ? (
                  <div className="simulator-vpl">
                    <SimulatorVPLAccumulated
                      data={economyData}
                      simulationType={"ML"}
                    />
                  </div>
                ) : simulacaoSelected === "GDXAPE" ? (
                  <div className="simulator-vpl">
                    <SimulatorVPL
                      data={economyData}
                      simulationType={"GDXAPE"}
                    />
                  </div>
                ) : (
                  <div className="simulator-vpl">
                    <SimulatorVPL
                      data={economyData}
                      simulationType={"MLXAPE"}
                    />
                  </div>
                )}
              </>
            )}

          {economyData?.dadosApeXml?.length > 0 &&
            simulacaoSelected === "MLXAPE" && (
              <>
                <div className="simulacao-header-title">
                  <h1>Economia anual</h1>
                  <h2>
                    Veja a projeção de economia anual com o preço da energia
                    atualizada para os próximos anos
                  </h2>
                </div>

                {economyData.dadosApeXml.map(
                  (e, i) =>
                    i < 5 && (
                      <>
                        <div style={{ marginTop: "16px" }}>
                          <span className="simulacao-form-header">{e.ano}</span>
                          <hr />
                        </div>

                        <div className="resultadoSimulacaoContainer2" key={i}>
                          <div className="economia-card2">
                            <h1>Custo livre</h1>
                            <h2>
                              {e.livre.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </h2>
                          </div>

                          <div className="economia-card2">
                            <h1>Custo autoprodutor</h1>
                            <h2>
                              {e.ape.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </h2>
                          </div>

                          <div className="economia-card2">
                            <h1>Economia mensal</h1>
                            <div className="economia-value">
                              <h2>
                                {e.economiaMensal.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </h2>
                              <h3
                                className={
                                  e.economiaMensal >= 0
                                    ? "economia-positivo"
                                    : "economia-negativo"
                                }
                              >
                                {e.economiaPct.toLocaleString("pt-BR", {
                                  style: "percent",
                                })}
                              </h3>
                            </div>
                          </div>

                          <div className="economia-card2">
                            <h1>Economia anual</h1>
                            <div className="economia-value">
                              <h2>
                                {e.economiaAnual.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                )}

                <div className="simulacao-header-title">
                  <h1>Projeções autoprodutor</h1>
                  <h2>
                    Veja a projeção de payback do projeto e o gráfico com o
                    fluxo de caixa anual, utilizando o valor presente, com os
                    reajustes de IPCA e conexão inclusos
                  </h2>
                </div>

                <div className="resultadoSimulacaoContainer">
                  <TIRPaybackTable
                    tir={economyData.tir}
                    vpl10anos={
                      economyData.vplAnual.slice(0, 11)[
                        economyData.vplAnual.slice(0, 11).length - 1
                      ]
                    }
                    vpl30anos={
                      economyData.vplAnual[economyData.vplAnual.length - 1]
                    }
                    paybackAnual={economyData.payBackAnual}
                  />
                </div>

                <div className="simulator-vpl">
                  <SimulatorVPL data={economyData} simulationType={"MLXAPE"} />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "40px",
                  }}
                >
                  <PDFDownloadLink
                    document={
                      <PDFFileApeXMl
                        economyData={economyData}
                        simulatorData={simulatorData}
                        livreData={livreData}
                        apeData={apeData}
                        screenShotChart={screenShotChart}
                        cativoData={cativoData}
                        desconto={formik.values.desconto}
                        infoUsina={{
                          usinaLocal: formik.values.local,
                          simultaneidade: formik.values.simultaneidade,
                          geracao: +formik.values.geracao
                            .replace(".", "")
                            .replace(",", "."),
                          demandaUsina: +formik.values.demandaUsina
                            .replace(".", "")
                            .replace(",", "."),
                          potenciaUsina: +formik.values.potenciaUsina
                            .replace(".", "")
                            .replace(",", "."),
                          custoProjeto: +formik.values.custoProjeto
                            .replace(".", "")
                            .replace(",", "."),
                        }}
                        hasCompanyLogo={checkCompanyLogo()}
                      />
                    }
                    fileName={fileName}
                  >
                    <button className="simulacao-button-selected">
                      GERAR SIMULAÇÃO EM PDF
                      <FiDownload
                        style={{
                          marginRight: "5px",
                          position: "relative",
                          top: "-3px",
                          left: "6px",
                        }}
                      />
                    </button>
                  </PDFDownloadLink>
                </div>
              </>
            )}
        </div>

        {simulacaoSelected === "ML" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <PDFDownloadLink
              document={
                <PDFFile
                  economyData={economyData}
                  simulatorData={simulatorData}
                  livreData={livreData}
                  apeData={apeData}
                  screenShotChart={screenShotChart}
                  cativoData={cativoData}
                  desconto={formik.values.desconto}
                  hasCompanyLogo={checkCompanyLogo()}
                />
              }
              fileName={fileName}
            >
              <button className="simulacao-button-selected">
                GERAR SIMULAÇÃO EM PDF
                <FiDownload
                  style={{
                    marginRight: "5px",
                    position: "relative",
                    top: "-3px",
                    left: "6px",
                  }}
                />
              </button>
            </PDFDownloadLink>
          </div>
        )}

        {simulacaoSelected === "GDXAPE" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <PDFDownloadLink
              document={
                <PDFFileGdXApe
                  economyData={economyData}
                  simulatorData={simulatorData}
                  gdData={gdData}
                  apeData={apeData}
                  screenShotChart={screenShotChart}
                  desconto={formik.values.desconto}
                  hasCompanyLogo={checkCompanyLogo()}
                />
              }
              fileName={fileName}
            >
              <button className="simulacao-button-selected">
                GERAR SIMULAÇÃO EM PDF
                <FiDownload
                  style={{
                    marginRight: "5px",
                    position: "relative",
                    top: "-3px",
                    left: "6px",
                  }}
                />
              </button>
            </PDFDownloadLink>
          </div>
        )}

        {simulacaoSelected === "APE" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
            }}
          >
            <PDFDownloadLink
              document={
                <PDFFileApe
                  economyData={economyData}
                  simulatorData={simulatorData}
                  livreData={livreData}
                  apeData={apeData}
                  screenShotChart={screenShotChart}
                  cativoData={cativoData}
                  desconto={formik.values.desconto}
                  infoUsina={{
                    usinaLocal: formik.values.local,
                    simultaneidade: formik.values.simultaneidade,
                    geracao: +formik.values.geracao
                      .replace(".", "")
                      .replace(",", "."),
                    demandaUsina: +formik.values.demandaUsina
                      .replace(".", "")
                      .replace(",", "."),
                    potenciaUsina: +formik.values.potenciaUsina
                      .replace(".", "")
                      .replace(",", "."),
                    custoProjeto: +formik.values.custoProjeto
                      .replace(".", "")
                      .replace(",", "."),
                  }}
                  hasCompanyLogo={checkCompanyLogo()}
                />
              }
              fileName={fileName}
            >
              <button className="simulacao-button-selected">
                GERAR SIMULAÇÃO EM PDF
                <FiDownload
                  style={{
                    marginRight: "5px",
                    position: "relative",
                    top: "-3px",
                    left: "6px",
                  }}
                />
              </button>
            </PDFDownloadLink>
          </div>
        )}
      </div>

      {screenShotChart !== "" &&
        (simulacaoSelected === "APE" || simulacaoSelected === "MLXAPE") &&
        formik.values.generateProposal && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 0",
              }}
            >
              <PDFDownloadLink
                document={
                  <PdFFileSelfProduction
                    proposalData={proposalData}
                    economyData={economyData}
                    screenShotChart={screenShotChart}
                    simulacaoSelected={simulacaoSelected}
                    cativoLivreData={
                      simulacaoSelected === "MLXAPE" ? livreData : cativoData
                    }
                    apeData={apeData}
                  />
                }
                fileName={fileProposalName}
              >
                <button className="simulacao-button-selected">
                  GERAR PROPOSTA EM PDF
                  <FiDownload
                    style={{
                      marginRight: "5px",
                      position: "relative",
                      top: "-3px",
                      left: "6px",
                    }}
                  />
                </button>
              </PDFDownloadLink>
            </div>
          </>
        )}
    </div>
  );
};

export default FormSimuacao;
